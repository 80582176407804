.header{
    position: fixed;
    top: 0;
    z-index: 10;
    background-color: $white;
    border-bottom: 1px solid $light-gray;
    padding: 21px 0px;
    width: 100%;

    &__content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 9;
    }
    &__logo-container{
        max-width: 170px;
        a{
            img{
                @include image-responsive(block);
            }
        }
    }
    &__primary-nav{
        @include rwd(900) {
            display: none;
        }

        ul {
            display: flex;
            column-gap: 40px;
            align-items: center;
            
            li {
                display: flex;
                column-gap: 16px;
                align-items: center;

                .btn-small{
                    padding: 8px 20px;
                    font-weight: 700;
                    min-height: 40px;
                }
            }
        }
    }
    &__menu-button{
        display: none;

        @include rwd(900) {
            display: block;
        }

        .header-toggler {
            font-weight: 700;
            position: relative;
            z-index: 3;
            cursor: pointer;
            border: none;
            background: none;
            padding: 0;
            margin: 0;
            margin-left: 1.5rem;
            display: flex;
            align-items: center;
            &:hover,
            &:focus,
            &:active {
                outline: none;
            }
            .toggler-text {
                font-size: .7rem;
                color: $black;
                &:before,
                &:after {
                    transition: all .4s ease-in-out;
                }
                &:before {
                    content: '';
                    visibility: visible;
                    opacity: 1;
                    position: absolute;
                }
                &:after {
                    content: '';
                    visibility: hidden;
                    opacity: 0;
                }

                @include rwd(900) {
                    display: none;
                }
            }

            @include rwd(900) {
                &.active {
                    .toggler-text {
                        display: block;
                    }
                }
            }
            .toggler-icon {
                width: 20px;
                height: 20px;
                position: relative;
                margin-left: .5rem;
                transform: rotate(0deg);
                transition: .5s ease-in-out;
                span {
                    display: block;
                    position: absolute;
                    height: 2px;
                    width: 100%;
                    background: $black;
                    border-radius: 5px;
                    opacity: 1;
                    left: 0;
                    transform: rotate(0deg);
                    transition: .25s ease-in-out;

                    &:nth-child(1) {
                        top: 3px;
                    }

                    &:nth-child(2) {
                        top: 8.5px;
                        height: 2.5px;
                    }

                    &:nth-child(3) {
                        top: 15px;
                        height: 2px;
                    }
                }
            }
            &.active {
                .toggler-text {
                    &:before {
                        visibility: hidden;
                        opacity: 0;
                    }
    
                    &:after {
                        visibility: visible;
                        opacity: 1;
                    }
                }
                .toggler-icon {
                    span {
                        &:nth-child(1) {
                            top: 8.5px;
                            transform: rotate(135deg);
                        }
    
                        &:nth-child(2) {
                            opacity: 0;
                        }
    
                        &:nth-child(3) {
                            top: 8.5px;
                            transform: rotate(-135deg);
                        }
                    }
                }
            }
        }
    }

    &__secondary-nav {
        position: fixed;
        z-index: 2;
        right: 0;
        top: 0;
        bottom: 0;
        min-width: 20rem;
        width: 100%;
        background-color: $white;
        visibility: hidden;
        transform: translateX(100%);
        transition: all 300ms ease-in-out;

        @include rwd(900) {
            &.open{
                visibility: visible;
                transform: translateX(0);
                height: 100vh;
                min-width: auto;
                transition: all $timming ease-in-out;
            }
            &__list{
                position: absolute;
                top: 4.5rem;
                bottom: 0;
                right: 0;
                left: 0;
                overflow-y: auto;
                overflow-x: hidden;
                background-color: $white;
                border-top: 1px solid $light-gray;

                ul {
                    display: flex;
                    flex-direction: column;
                    margin-top: 30px;
                    row-gap: 30px;

                    &:last-child {
                        margin-top: 30px;
                        padding-top: 30px;
                        border-top: 1px solid $light-gray;

                        .primary-btn {
                            display: block;
                            width: 100%;
                        }
                    }

                    li {
                        padding: 0 16px;
                    }
                }
            }
        }
    }
}