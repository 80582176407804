/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: "ToyotaType";
  src: url("../fonts/ToyotaType/ToyotaType-Light.eot");
  src: url("../fonts/ToyotaType/ToyotaType-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/ToyotaType/ToyotaType-Light.woff2") format("woff2"), url("../fonts/ToyotaType/ToyotaType-Light.woff") format("woff"), url("../fonts/ToyotaType/ToyotaType-Light.ttf") format("truetype"), url("../fonts/ToyotaType/ToyotaType-Light.svg#ToyotaType-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "ToyotaType";
  src: url("../fonts/ToyotaType/ToyotaType-Regular.eot");
  src: url("../fonts/ToyotaType/ToyotaType-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/ToyotaType/ToyotaType-Regular.woff2") format("woff2"), url("../fonts/ToyotaType/ToyotaType-Regular.woff") format("woff"), url("../fonts/ToyotaType/ToyotaType-Regular.ttf") format("truetype"), url("../fonts/ToyotaType/ToyotaType-Regular.svg#ToyotaType-Regular") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "ToyotaType";
  src: url("../fonts/ToyotaType/ToyotaType-Book.eot");
  src: url("../fonts/ToyotaType/ToyotaType-Book.eot?#iefix") format("embedded-opentype"), url("../fonts/ToyotaType/ToyotaType-Book.woff2") format("woff2"), url("../fonts/ToyotaType/ToyotaType-Book.woff") format("woff"), url("../fonts/ToyotaType/ToyotaType-Book.ttf") format("truetype"), url("../fonts/ToyotaType/ToyotaType-Book.svg#ToyotaType-Book") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "ToyotaType";
  src: url("../fonts/ToyotaType/ToyotaType-Semibold.eot");
  src: url("../fonts/ToyotaType/ToyotaType-Semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/ToyotaType/ToyotaType-Semibold.woff2") format("woff2"), url("../fonts/ToyotaType/ToyotaType-Semibold.woff") format("woff"), url("../fonts/ToyotaType/ToyotaType-Semibold.ttf") format("truetype"), url("../fonts/ToyotaType/ToyotaType-Semibold.svg#ToyotaType-Semibold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, html {
  width: 100%;
  scroll-behavior: smooth;
}
body.no-scroll, html.no-scroll {
  overflow: hidden;
}
body.block, html.block {
  pointer-events: none;
}

body, button {
  font-family: "ToyotaType", sans-serif;
}

.wrapper {
  display: block;
  width: 100%;
  position: relative;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 80px;
  padding-right: 80px;
}
@media (max-width: 1023px) {
  .wrapper {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media (max-width: 719px) {
  .wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }
}

section {
  padding-top: 60px;
  padding-bottom: 60px;
}
section:first-of-type {
  padding-top: 83px;
}
section.page-top-navigation {
  margin-top: 44px;
  padding-bottom: 24px;
}
section.page-top-navigation + section {
  padding-top: 0;
}
section.page-top-navigation svg {
  margin-top: 5px;
}
@media (max-width: 719px) {
  section:first-of-type {
    padding-top: 67px;
  }
}

strong {
  font-weight: 700;
}

a {
  text-decoration: none;
  color: inherit;
}

.btn-large {
  padding: 8px 24px;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.375;
  letter-spacing: 0em;
  background-repeat: no-repeat;
  background-position: center right 16px;
}
@media (max-width: 719px) {
  .btn-large {
    padding: 8px 16px;
  }
}
@media (max-width: 575px) {
  .btn-large {
    width: 100%;
  }
}

.btn-small {
  padding: 4px 16px;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.2857142857;
  letter-spacing: 0em;
}
@media (max-width: 575px) {
  .btn-small {
    width: 100%;
  }
}

.btn-large, .btn-small {
  border-radius: 48px;
  border: none;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  text-align: center;
}
.btn-large.primary-btn, .btn-small.primary-btn {
  background-color: #3A00D5;
  border: 1px solid #3A00D5;
  color: #FFFFFF;
  transition: all 0.2s ease-in-out;
}
.btn-large.primary-btn.disabled, .btn-small.primary-btn.disabled {
  background-color: #E8E8E8;
  border-color: #E8E8E8;
  color: #454545;
  pointer-events: none;
  cursor: default;
}
.btn-large.primary-btn:hover, .btn-small.primary-btn:hover {
  background-color: #1A0060;
  border: 1px solid #1A0060;
}
.btn-large.primary-btn:focus, .btn-small.primary-btn:focus {
  box-shadow: 0px 0px 0px 4px rgba(76, 13, 224, 0.2);
}
.btn-large.secondary-btn, .btn-small.secondary-btn {
  background-color: #191919;
  color: #FFFFFF;
  transition: all 0.2s ease-in-out;
}
.btn-large.secondary-btn.disabled, .btn-small.secondary-btn.disabled {
  background-color: #E8E8E8;
  color: #454545;
  pointer-events: none;
}
.btn-large.secondary-btn:hover, .btn-small.secondary-btn:hover {
  background-color: #1A0060;
  color: #FFFFFF;
}
.btn-large.secondary-btn:focus, .btn-small.secondary-btn:focus {
  background-color: #1A0060;
  color: #FFFFFF;
  box-shadow: 0px 0px 0px 4px rgba(76, 13, 224, 0.2);
}
.btn-large.black-btn, .btn-small.black-btn {
  background-color: #000000;
  border: 1px solid #000000;
  color: #FFFFFF;
  transition: all 0.2s ease-in-out;
}
.btn-large.black-btn.disabled, .btn-small.black-btn.disabled {
  background-color: #E8E8E8;
  border-color: #E8E8E8;
  color: #454545;
  pointer-events: none;
  cursor: default;
}
.btn-large.black-btn:hover, .btn-small.black-btn:hover {
  background-color: #191919;
  border: 1px solid #191919;
}
.btn-large.black-btn:focus, .btn-small.black-btn:focus {
  box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.2);
}
.btn-large.outline-btn, .btn-small.outline-btn {
  background-color: #FFFFFF;
  color: #3A00D5;
  border: 1px solid #3A00D5;
  transition: all 0.2s ease-in-out;
}
.btn-large.outline-btn.with-icon, .btn-small.outline-btn.with-icon {
  display: flex;
  align-items: center;
  gap: 8px;
}
.btn-large.outline-btn.disabled, .btn-small.outline-btn.disabled {
  background-color: #F7F7F7;
  color: #454545;
  border: 1px solid #A7A7A8;
  pointer-events: none;
}
.btn-large.outline-btn svg.stroke *, .btn-small.outline-btn svg.stroke * {
  transition: stroke 0.2s ease-in-out;
}
.btn-large.outline-btn:hover, .btn-small.outline-btn:hover {
  background-color: #3A00D5;
  color: #FFFFFF;
  border: 1px solid #3A00D5;
}
.btn-large.outline-btn:hover svg.stroke *, .btn-small.outline-btn:hover svg.stroke * {
  stroke: currentColor;
}
.btn-large.outline-btn:focus, .btn-small.outline-btn:focus {
  background-color: #3A00D5;
  color: #FFFFFF;
  border: 1px solid #3A00D5;
  box-shadow: 0px 0px 0px 4px rgba(76, 13, 224, 0.2);
}
.btn-large.icon-btn, .btn-small.icon-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px !important;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #FFFFFF;
  color: #3A00D5;
  border: 1px solid #3A00D5;
  transition: all 0.2s ease-in-out;
}
.btn-large.icon-btn.disabled, .btn-small.icon-btn.disabled {
  background-color: #F7F7F7;
  color: #454545;
  border: 1px solid #A7A7A8;
  pointer-events: none;
}
.btn-large.icon-btn svg.stroke *, .btn-small.icon-btn svg.stroke * {
  transition: stroke 0.2s ease-in-out;
}
.btn-large.icon-btn:hover, .btn-small.icon-btn:hover {
  background-color: #3A00D5;
  color: #FFFFFF;
  border: 1px solid #3A00D5;
}
.btn-large.icon-btn:hover svg.stroke *, .btn-small.icon-btn:hover svg.stroke * {
  stroke: currentColor;
}
.btn-large.icon-btn:focus, .btn-small.icon-btn:focus {
  background-color: #3A00D5;
  color: #FFFFFF;
  border: 1px solid #3A00D5;
  box-shadow: 0px 0px 0px 4px rgba(76, 13, 224, 0.2);
}
.btn-large.block-btn, .btn-small.block-btn {
  width: 100%;
}
.btn-large.price-btn, .btn-small.price-btn {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.2222222222;
  letter-spacing: normal;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  width: 100%;
  padding: 16px 24px;
  border-radius: 16px;
  border: 1px solid #3A00D5;
  color: #3A00D5;
  background-color: #FFFFFF;
  text-align: left;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}
.btn-large.price-btn.not-clickable, .btn-small.price-btn.not-clickable {
  pointer-events: none;
}
.btn-large.price-btn.disabled, .btn-small.price-btn.disabled {
  pointer-events: none;
  border-color: #E8E8E8;
  background-color: transparent;
  color: #454545;
}
.btn-large.price-btn .break, .btn-small.price-btn .break {
  display: none;
  flex-grow: 1;
  flex-basis: 100%;
}
.btn-large.price-btn .break a, .btn-small.price-btn .break a {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.4285714286;
  letter-spacing: 0em;
  color: inherit;
  text-decoration: underline;
}
.btn-large.price-btn.--selected, .btn-small.price-btn.--selected {
  background-color: #3A00D5;
  border: 1px solid #3A00D5;
  color: #FFFFFF;
}
.btn-large.price-btn.--selected.--active .break, .btn-small.price-btn.--selected.--active .break {
  display: block;
}
.btn-large.price-btn.--selected.disabled, .btn-small.price-btn.--selected.disabled {
  pointer-events: none;
  background-color: #E8E8E8;
  border-color: #E8E8E8;
  color: #454545;
}
.btn-large.fb-btn, .btn-small.fb-btn {
  color: #FFFFFF;
  border: 1px solid #3A00D5;
  background: url("../images/facebook-white.svg") no-repeat 16px center, #3C4F8C;
}
.btn-large.fb-btn:hover, .btn-small.fb-btn:hover {
  background: url("../images/facebook-white.svg") no-repeat 16px center, #324174;
}
.btn-large.google-btn, .btn-small.google-btn {
  color: #454545;
  border: 1px solid #A7A7A8;
  background: url("../images/google.svg") no-repeat 16px center, #FFFFFF;
}
.btn-large.google-btn:hover, .btn-small.google-btn:hover {
  background: url("../images/google.svg") no-repeat 16px center, #F7F7F7;
}
.btn-large.--loading, .btn-large.loading, .btn-small.--loading, .btn-small.loading {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  pointer-events: none;
}
.btn-large.--loading::after, .btn-large.loading::after, .btn-small.--loading::after, .btn-small.loading::after {
  content: "";
  display: inline-block;
  width: 22px;
  height: 22px;
  background: url("../images/spinner.svg") no-repeat center center;
  background-size: contain;
  animation: spin 2s infinite linear;
}

.btn-small.--loading::after, .btn-small.loading::after {
  width: 18px;
  height: 18px;
}

.text-link {
  cursor: pointer;
  background: none;
  border: 0;
  padding: 0;
}
.text-link.with-icon {
  display: inline-flex;
  align-items: center;
  gap: 10px;
}
.text-link.with-icon svg * {
  stroke: currentColor;
}
.text-link.primary-link {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.4285714286;
  letter-spacing: 0em;
  color: #3A00D5;
  text-decoration: underline;
}
.text-link.primary-link.inherit {
  font-size: inherit;
  line-height: inherit;
}
.text-link.primary-link.disabled {
  color: #A7A7A8;
  pointer-events: none;
}
.text-link.primary-link:hover {
  color: #1A0060;
}
.text-link.secondary-link {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0em;
  color: #191919;
}
.text-link.danger-link {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.4285714286;
  letter-spacing: 0em;
  color: #D92D20;
  text-decoration: underline;
}
.text-link.danger-link.disabled {
  color: #A7A7A8;
  pointer-events: none;
}
.text-link.black-link {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.4285714286;
  letter-spacing: 0em;
  color: #000000;
  text-decoration: underline;
}
.text-link.black-link.disabled {
  color: #A7A7A8;
  pointer-events: none;
}
.text-link.black-link:hover {
  color: #1A0060;
}
.text-link.nav-link {
  color: #000000;
  transition: color 0.2s ease-in-out;
  text-decoration: none;
  display: inline-block;
}
.text-link.nav-link::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  margin-top: 4px;
  background: #3A00D5;
  transition: transform 0.2s ease-in-out;
  transform: scale(0);
}
.text-link.nav-link:hover {
  color: #3A00D5;
}
.text-link.nav-link:hover::after {
  transform: scale(1);
}
.text-link.nav-link--active {
  display: inline-block;
  color: #3A00D5;
}
.text-link.nav-link--active::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  margin-top: 4px;
  background: #3A00D5;
  transition: transform 0.2s ease-in-out;
  transform: scale(1);
}

.social-media-btn {
  border-radius: 50%;
  background-color: #FFFFFF;
  padding: 8px;
  width: 40px;
  height: 40px;
  display: block;
}

.social-media-btn:hover {
  background-color: #1A0060;
}
.social-media-btn:hover img {
  filter: brightness(0) invert(1);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.ego-form {
  width: 100%;
  margin: 0 auto;
}
.ego-form__field {
  width: 100%;
  position: relative;
  margin-bottom: 40px;
  background-color: transparent;
  z-index: 4;
}
.ego-form__field__select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding-right: 45px;
}
.ego-form__field__select::-ms-expand {
  display: none;
}
.ego-form__field__input, .ego-form__field__select, .ego-form__field__radio-label, .ego-form__field__file {
  display: block;
  position: relative;
  z-index: 0;
  border: 2px solid #A7A7A8;
  background-color: #FFFFFF;
  border-radius: 8px;
  padding: 18px 16px 6px 16px;
  height: 48px;
  width: 100%;
  color: #000000;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0em;
  font-family: "ToyotaType", sans-serif;
  transition: all 0.2s ease-in-out;
  background-repeat: no-repeat;
  background-position: center right 16px;
}
.ego-form__field__input:hover, .ego-form__field__select:hover, .ego-form__field__radio-label:hover, .ego-form__field__file:hover {
  border-color: #3A00D5;
}
.ego-form__field__input:focus, .ego-form__field__select:focus, .ego-form__field__radio-label:focus, .ego-form__field__file:focus {
  border-color: #3A00D5;
  box-shadow: 0px 0px 0px 4px rgba(76, 13, 224, 0.2);
  outline: none;
  outline-color: #000000;
}
.ego-form__field__input:focus + label, .ego-form__field__select:focus + label, .ego-form__field__radio-label:focus + label, .ego-form__field__file:focus + label {
  position: absolute;
  top: 7px;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0em;
}
.ego-form__field__label {
  pointer-events: none;
  position: absolute;
  top: 15px;
  left: 0px;
  padding-left: 17px;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0em;
  color: #454545;
  user-select: none;
  cursor: text;
  width: 100%;
  height: 100%;
  text-align: left;
  transition: all 0.2s ease-in-out;
}
.ego-form__field__textarea {
  padding-top: 1.4rem;
  height: 100%;
  resize: none;
}
.ego-form__field__textarea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-style: italic;
}
.ego-form__field__textarea::-moz-placeholder { /* Firefox 19+ */
  font-style: italic;
}
.ego-form__field__textarea:-moz-placeholder { /* Firefox 18- */
  font-style: italic;
}
.ego-form__field.--checkbox {
  cursor: pointer;
}
.ego-form__field.--checkbox .ego-form__field__label {
  pointer-events: all;
  position: static;
  padding: 0;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: normal;
}
.ego-form__field.--checkbox .ego-form__field__checkbox {
  appearance: none;
  background-color: #FFFFFF;
  margin: 0 6px 0 0;
  font: inherit;
  color: currentColor;
  width: 1.5em;
  height: 1.5em;
  border: 2px solid #A7A7A8;
  border-radius: 0.4em;
  transform: translateY(-0.075em);
  display: inline-grid;
  place-content: center;
  vertical-align: middle;
  transition: all 0.2s ease-in-out;
}
.ego-form__field.--checkbox .ego-form__field__checkbox::before {
  content: "";
  width: 0.8em;
  height: 0.8em;
  transform: scale(0);
  box-shadow: inset 1em 1em #FFFFFF;
  background-color: CanvasText;
  clip-path: polygon(18% 41%, 5% 55%, 37% 84%, 100% 20%, 87% 7%, 37% 58%);
  transition: all 0.2s ease-in-out;
}
.ego-form__field.--checkbox .ego-form__field__checkbox:hover {
  border-color: #3A00D5;
  background-color: #D2C7EF;
}
.ego-form__field.--checkbox .ego-form__field__checkbox:hover::before {
  box-shadow: inset 1em 1em #D2C7EF;
}
.ego-form__field.--checkbox .ego-form__field__checkbox:focus {
  box-shadow: 0px 0px 0px 4px rgba(76, 13, 224, 0.2);
  outline: none;
}
.ego-form__field.--checkbox .ego-form__field__checkbox:checked {
  background-color: #3A00D5;
  border-color: #3A00D5;
}
.ego-form__field.--checkbox .ego-form__field__checkbox:checked::before {
  transform: scale(1);
}
.ego-form__field.--checkbox.--has-error .ego-form__field__checkbox {
  border-color: #D92D20 !important;
}
.ego-form__field.--checkbox.--has-error .ego-form__field__checkbox:checked {
  background-color: #D92D20;
}
.ego-form__field.--checkbox .ego-form__field__error {
  position: static;
  margin: auto;
  display: table;
  margin-top: 10px;
}
.ego-form__field.--radio {
  cursor: pointer;
}
.ego-form__field.--radio .ego-form__field__label {
  display: block;
  pointer-events: all;
  position: static;
  padding: 0;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: normal;
}
.ego-form__field.--radio .ego-form__field__label:not(:last-child) {
  margin-bottom: 32px;
}
.ego-form__field.--radio .ego-form__field__radio {
  appearance: none;
  background-color: #FFFFFF;
  margin: 0 6px 0 0;
  font: inherit;
  color: currentColor;
  width: 1.5em;
  height: 1.5em;
  border: 2px solid #A7A7A8;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: inline-grid;
  place-content: center;
  vertical-align: middle;
  transition: all 0.2s ease-in-out;
}
.ego-form__field.--radio .ego-form__field__radio::before {
  content: "";
  width: 0.6em;
  height: 0.6em;
  border-radius: 50%;
  transform: scale(0);
  background-color: #FFFFFF;
  transition: transform 0.2s ease-in-out;
}
.ego-form__field.--radio .ego-form__field__radio:hover {
  border-color: #3A00D5;
  background-color: #D2C7EF;
}
.ego-form__field.--radio .ego-form__field__radio:hover::before {
  box-shadow: inset 1em 1em #D2C7EF;
}
.ego-form__field.--radio .ego-form__field__radio:focus {
  box-shadow: 0px 0px 0px 4px rgba(76, 13, 224, 0.2);
  outline: none;
}
.ego-form__field.--radio .ego-form__field__radio:checked {
  background-color: #3A00D5;
  border-color: #3A00D5;
}
.ego-form__field.--radio .ego-form__field__radio:checked::before {
  transform: scale(1);
}
.ego-form__field.--radio.--inline-radio {
  display: flex;
  flex-flow: row wrap;
  row-gap: 7px;
  column-gap: 3px;
  margin-bottom: 15px;
}
.ego-form__field.--radio.--inline-radio .ego-form__field__label {
  display: inline-block;
  width: auto;
  margin: 4px 0;
}
.ego-form__field.--radio.--inline-radio .ego-form__field__radio::before {
  display: none;
}
.ego-form__field.--radio.--inline-radio .ego-form__field__radio:checked {
  border-color: #FFFFFF;
  box-shadow: 0px 0px 0px 4px #3A00D5;
}
.ego-form__field.--radio.--has-error .ego-form__field__checkbox {
  border-color: #D92D20 !important;
}
.ego-form__field.--radio.--has-error .ego-form__field__checkbox:checked {
  background-color: #D92D20;
}
.ego-form__field.--radio .ego-form__field__error {
  position: static;
  margin: auto;
  display: table;
  margin-top: 10px;
}
.ego-form__field.--password input {
  padding-right: 52px;
}
.ego-form__field.--file .ego-form__field__file {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  padding: 1.3rem 1rem 1.3rem 1rem;
  cursor: pointer;
}
.ego-form__field.--file .ego-form__field__file .attachment-control {
  display: flex;
  align-items: center;
  min-height: 22px;
}
.ego-form__field.--file .ego-form__field__file .attachment-file-info {
  display: grid;
  grid-template-columns: auto minmax(60px, 1fr);
  align-items: center;
  gap: 10px;
}
.ego-form__field.--file .ego-form__field__file .attachment-file-info .attachment-file-name {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: normal;
  text-decoration: underline;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ego-form__field.--file .ego-form__field__file .attachment-file-info .attachment-file-size {
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: normal;
  color: #E8E8E8;
  min-width: 60px;
}
.ego-form__field.--file .ego-form__field__file .attachment-file-info .attachment-file-size i {
  color: #3A00D5;
  text-transform: uppercase;
}
.ego-form__field.--file .ego-form__field__file .attachment-file-info .attachment-file-size:empty:before {
  content: attr(data-placeholder);
}
.ego-form__field.--file .ego-form__field__file .icon__clip {
  height: 1rem;
}
.ego-form__field.--file .ego-form__field__file .attachment-remove {
  display: none;
  cursor: pointer;
  border: 0;
  background: none;
  outline: none;
  padding: 0;
  margin: 0 0 0 auto;
  font-size: 23px;
  line-height: 0.8;
}
.ego-form__field.--file.--has-file .ego-form__field__file {
  border-color: #3A00D5;
  box-shadow: 0px 0px 0px 4px rgba(76, 13, 224, 0.2);
  outline: none;
  outline-color: #000000;
}
.ego-form__field.--file.--has-file .ego-form__field__file .icon__clip {
  display: none;
}
.ego-form__field.--file.--has-file .ego-form__field__file .attachment-remove {
  display: block;
}
.ego-form__field.--file input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.ego-form__field.--disabled {
  pointer-events: none;
  opacity: 0.4;
}
.ego-form__field__error {
  opacity: 0;
  color: #D92D20;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0em;
  text-align: left;
  position: absolute;
  left: 0;
  top: 55px;
  display: flex;
  align-items: self-end;
  background-repeat: no-repeat;
  padding-left: 20px;
  transition: opacity 0.2s ease-in-out;
}
.ego-form__field__error img {
  margin-right: 9px;
}
.ego-form__field__error strong {
  font-weight: 400;
}
.ego-form__field__error.--active {
  opacity: 1;
}
.ego-form__field__warning {
  color: #454545;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0em;
  text-align: left;
  position: absolute;
  left: 0;
  top: 55px;
  display: flex;
  background-repeat: no-repeat;
  padding-left: 20px;
  height: 16px;
  transition: opacity 0.2s ease-in-out;
}
.ego-form__field__warning img {
  margin-right: 9px;
}
.ego-form__field__warning strong {
  font-weight: 400;
}
.ego-form__field.--filled .ego-form__field__input:not(:focus),
.ego-form__field.--filled .ego-form__field__select:not(:focus), .ego-form__field.--filled.--textarea:not(:focus) {
  border-color: #454545;
}
.ego-form__field.--filled .ego-form__field__label {
  position: absolute;
  top: 7px;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0em;
}
.ego-form__field.--optional::after, .ego-form__field.--custom-assistive::after {
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: normal;
  content: "OPTIONAL";
  position: absolute;
  right: 1rem;
  top: 1.1rem;
  color: #000000;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
}
@media (max-width: 719px) {
  .ego-form__field.--optional::after, .ego-form__field.--custom-assistive::after {
    font-size: 10px;
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 1;
    letter-spacing: normal;
    right: 1rem;
    top: 1.3rem;
  }
}
.ego-form__field.--optional.--filled::after, .ego-form__field.--custom-assistive.--filled::after {
  opacity: 0;
}
.ego-form__field.--optional::after {
  content: "OPTIONAL";
}
.ego-form__field.--custom-assistive::after {
  content: attr(data-assistive);
}
.ego-form__field.--has-error .ego-form__field__input,
.ego-form__field.--has-error .ego-form__field__select,
.ego-form__field.--has-error .ego-form__field__file, .ego-form__field.--has-error.--textarea {
  background-color: #FFFFFF;
  border-color: #D92D20 !important;
}
.ego-form__field.--has-error .ego-form__field__error {
  opacity: 1;
}
.ego-form__field.--has-error .ego-form__field__warning {
  opacity: 0;
}
.ego-form__field.--has-error.--optional:after {
  display: none !important;
}
.ego-form__field.--full-height {
  height: 100%;
}
.ego-form__field.--textarea {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 2px solid #A7A7A8;
  background-color: #FFFFFF;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
}
.ego-form__field.--textarea:hover {
  border-color: #000000;
}
.ego-form__field.--textarea:focus-within {
  border-color: #3A00D5;
  box-shadow: 0px 0px 0px 4px rgba(76, 13, 224, 0.2);
  outline: none;
  outline-color: #000000;
  outline-color: #000000;
}
.ego-form__field.--textarea textarea {
  display: block;
  font-family: "ToyotaType", sans-serif;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normal;
  padding: 0;
  margin: 1rem;
  border: 0;
  background: transparent;
  outline: none !important;
}
.ego-form__field.--textarea textarea::-webkit-scrollbar {
  width: 0.5em;
  padding-left: 10px;
}
.ego-form__field.--textarea textarea.--horizontal-scroll::-webkit-scrollbar {
  height: 0.5em;
}
.ego-form__field.--textarea textarea::-webkit-scrollbar-track {
  box-shadow: none;
  -webkit-box-shadow: none;
}
.ego-form__field.--textarea textarea::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  outline: 0;
  border-radius: 5px;
}
.ego-form__field.--textarea.--big {
  min-height: 280px;
}
.ego-form__field.--textarea .ego-form__field__label {
  opacity: 0.5;
  font-style: italic;
}
.ego-form__field.--textarea .ego-form__field__error {
  inset: 1px 1px auto auto;
  height: auto;
  width: fit-content;
  background-color: none;
  max-width: none;
  min-height: 70px;
  max-height: 50%;
}
.ego-form__field.--textarea .ego-form__field__footer {
  position: relative;
  height: auto;
  width: calc(100% - 2px);
  max-width: none;
  min-height: 50px;
  max-height: 50%;
  padding: 0 1rem 1rem;
  display: grid;
  grid-template-columns: auto 95px;
  align-items: end;
  gap: 20px;
}
.ego-form__field.--textarea .ego-form__field__footer .ego-form__field__attachment {
  min-width: 0;
  position: relative;
  z-index: 1;
}
.ego-form__field.--textarea .ego-form__field__footer .ego-form__field__attachment .attachment-label {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}
.ego-form__field.--textarea .ego-form__field__footer .ego-form__field__attachment .attachment-label .attachment-control {
  display: flex;
  align-items: center;
  min-height: 22px;
}
.ego-form__field.--textarea .ego-form__field__footer .ego-form__field__attachment .attachment-label .attachment-file-info {
  min-width: 0;
  display: flex;
  align-items: center;
  gap: 10px;
}
.ego-form__field.--textarea .ego-form__field__footer .ego-form__field__attachment .attachment-label .attachment-file-info .attachment-file-name {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: normal;
  max-width: 100%;
  text-decoration: underline;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ego-form__field.--textarea .ego-form__field__footer .ego-form__field__attachment .attachment-label .attachment-file-info .attachment-file-size {
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: normal;
  color: #E8E8E8;
  min-width: 60px;
}
.ego-form__field.--textarea .ego-form__field__footer .ego-form__field__attachment .attachment-label .attachment-file-info .attachment-file-size i {
  color: #3A00D5;
  text-transform: uppercase;
}
.ego-form__field.--textarea .ego-form__field__footer .ego-form__field__attachment .attachment-label .attachment-file-info .attachment-file-size:empty:before {
  content: attr(data-placeholder);
}
.ego-form__field.--textarea .ego-form__field__footer .ego-form__field__attachment .attachment-label .icon__clip {
  display: block;
  width: 8px;
  height: 16px;
}
.ego-form__field.--textarea .ego-form__field__footer .ego-form__field__attachment .attachment-label .attachment-remove {
  display: none;
  cursor: pointer;
  border: 0;
  background: none;
  outline: none;
  padding: 0;
  margin: 0 0 0 auto;
  font-size: 23px;
  line-height: 0.6;
}
.ego-form__field.--textarea .ego-form__field__footer .ego-form__field__attachment.--has-file .icon__clip {
  display: none;
}
.ego-form__field.--textarea .ego-form__field__footer .ego-form__field__attachment.--has-file .attachment-remove {
  display: block;
}
.ego-form__field.--textarea .ego-form__field__footer .ego-form__field__attachment input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.ego-form__field.--textarea .ego-form__field__footer .ego-form__field__length-counter {
  position: relative;
  z-index: 1;
  text-align: right;
  margin-left: auto;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: normal;
  color: #000000;
}
.ego-form__field.--textarea .ego-form__field__footer .ego-form__progress-bar {
  content: "";
  position: absolute;
  inset: 0 auto 0 0;
  width: 0%;
  background-color: yellow;
}
.ego-form__field.--select {
  display: block;
  position: relative;
  z-index: 0;
  height: 48px;
  width: 100%;
  color: #000000;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0em;
  font-family: "ToyotaType", sans-serif;
  transition: all 0.2s ease-in-out;
  cursor: default;
  border: 2px solid #A7A7A8;
  border-radius: 8px;
  z-index: 5;
}
.ego-form__field.--select .ego-form__field__label {
  top: 13px;
}
.ego-form__field.--select:hover {
  border-color: #3A00D5;
}
.ego-form__field.--select:focus, .ego-form__field.--select.--open {
  border-color: #3A00D5;
  box-shadow: 0px 0px 0px 4px rgba(76, 13, 224, 0.2);
  outline: none;
  outline-color: #000000;
  z-index: 6;
}
.ego-form__field.--select:focus + label, .ego-form__field.--select.--open + label {
  position: absolute;
  top: 7px;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0em;
}
.ego-form__field.--select.--filled {
  border-color: #454545;
}
.ego-form__field.--select.--filled .ego-form__field__label {
  top: 5px;
}
.ego-form__field.--select.--has-error {
  border-color: #D92D20 !important;
}
.ego-form__field.--select .caret {
  content: "";
  display: inline-block;
  width: 22px;
  height: 22px;
  background: url("../images/dropdown-arrow.svg") no-repeat center center;
  background-size: contain;
}
.ego-form__field.--select .ego-form__field__select-display {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
  color: #454545;
}
.ego-form__field.--select .ego-form__field__select-display .value-placeholder {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: normal;
  margin-top: 10px;
}
.ego-form__field.--select .ego-form__field__select-dropdown {
  position: relative;
  z-index: 1;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  border: 1px solid #E8E8E8;
  border-radius: 8px;
  padding: 8px 0;
  margin-left: -3px;
  margin-top: 8px;
  max-height: 160px;
  overflow-x: auto;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-in-out, color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}
.ego-form__field.--select .ego-form__field__select-dropdown::-webkit-scrollbar {
  width: 0.5em;
  padding-left: 10px;
}
.ego-form__field.--select .ego-form__field__select-dropdown.--horizontal-scroll::-webkit-scrollbar {
  height: 0.5em;
}
.ego-form__field.--select .ego-form__field__select-dropdown::-webkit-scrollbar-track {
  box-shadow: none;
  -webkit-box-shadow: none;
}
.ego-form__field.--select .ego-form__field__select-dropdown::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  outline: 0;
  border-radius: 5px;
}
.ego-form__field.--select .ego-form__field__select-dropdown ul {
  display: flex;
  flex-direction: column;
}
.ego-form__field.--select .ego-form__field__select-dropdown ul li.option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 38px;
  padding: 0 16px;
  width: 100%;
}
.ego-form__field.--select .ego-form__field__select-dropdown ul li.option:not(.--disabled):hover, .ego-form__field.--select .ego-form__field__select-dropdown ul li.option:not(.--selected):hover {
  background-color: #F7F7F7;
}
.ego-form__field.--select .ego-form__field__select-dropdown ul li.option.--selected {
  background-color: #3A00D5 !important;
  color: #FFFFFF;
}
.ego-form__field.--select .ego-form__field__select-dropdown ul li.option.--disabled {
  color: #E8E8E8;
  pointer-events: none;
  cursor: not-allowed;
}
.ego-form__field.--select.--open .ego-form__field__select-dropdown {
  opacity: 1;
  pointer-events: all;
}
.ego-form__field.--select.--drop-up .ego-form__field__select-dropdown {
  position: absolute;
  inset: auto 0 calc(100% + 10px) 0;
  box-shadow: 0px -4px 8px -2px rgba(16, 24, 40, 0.1), 0px -2px 4px -2px rgba(16, 24, 40, 0.06);
}
.ego-form__field.--select-text {
  display: inline-block;
  position: relative;
  z-index: 0;
  width: auto;
  height: 25px;
  margin-bottom: 0;
  padding: 0;
  color: #000000;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0em;
  font-family: "ToyotaType", sans-serif;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  border: 0;
  z-index: 5;
}
.ego-form__field.--select-text.--has-error {
  border-color: #D92D20 !important;
}
.ego-form__field.--select-text .caret {
  content: "";
  display: inline-block;
  width: 22px;
  height: 22px;
  background: url("../images/dropdown-arrow-blue.svg") no-repeat center center;
  background-size: contain;
}
.ego-form__field.--select-text .ego-form__field__select-display {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0;
  transition: all 0.2s ease-in-out;
  color: #3A00D5;
}
.ego-form__field.--select-text .ego-form__field__select-display .value-placeholder {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: normal;
  text-decoration: underline;
  text-underline-offset: 4px;
}
.ego-form__field.--select-text .ego-form__field__select-dropdown {
  position: relative;
  z-index: 1;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
  padding: 8px 0;
  margin-left: -3px;
  margin-top: 8px;
  max-height: 160px;
  overflow-x: auto;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-in-out, color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}
.ego-form__field.--select-text .ego-form__field__select-dropdown::-webkit-scrollbar {
  width: 0.5em;
  padding-left: 10px;
}
.ego-form__field.--select-text .ego-form__field__select-dropdown.--horizontal-scroll::-webkit-scrollbar {
  height: 0.5em;
}
.ego-form__field.--select-text .ego-form__field__select-dropdown::-webkit-scrollbar-track {
  box-shadow: none;
  -webkit-box-shadow: none;
}
.ego-form__field.--select-text .ego-form__field__select-dropdown::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  outline: 0;
  border-radius: 5px;
}
.ego-form__field.--select-text .ego-form__field__select-dropdown ul {
  display: flex;
  flex-direction: column;
}
.ego-form__field.--select-text .ego-form__field__select-dropdown ul li.option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 38px;
  padding: 0 16px;
  width: 100%;
}
.ego-form__field.--select-text .ego-form__field__select-dropdown ul li.option:not(.--disabled):hover, .ego-form__field.--select-text .ego-form__field__select-dropdown ul li.option:not(.--selected):hover {
  background-color: #F7F7F7;
}
.ego-form__field.--select-text .ego-form__field__select-dropdown ul li.option.--selected {
  background-color: #3A00D5 !important;
  color: #FFFFFF;
}
.ego-form__field.--select-text .ego-form__field__select-dropdown ul li.option.--disabled {
  color: #E8E8E8;
  pointer-events: none;
  cursor: not-allowed;
}
.ego-form__field.--select-text:hover .ego-form__field__select-display {
  color: #1A0060;
}
.ego-form__field.--select-text.--open {
  z-index: 6;
}
.ego-form__field.--select-text.--open .caret {
  transform: rotate(180deg);
}
.ego-form__field.--select-text.--open .ego-form__field__select-dropdown {
  opacity: 1;
  pointer-events: all;
}
.ego-form__field.--select-text.--drop-up .ego-form__field__select-dropdown {
  position: absolute;
  inset: auto 0 calc(100% + 10px) 0;
}
.ego-form__field.--select:first-child {
  z-index: 6;
}
.ego-form__field.--select-react {
  display: block;
  position: relative;
  z-index: 0;
  height: 48px;
  width: 100%;
  color: #000000;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0em;
  font-family: "ToyotaType", sans-serif;
  transition: all 0.2s ease-in-out;
  cursor: default;
  border: 2px solid #A7A7A8;
  border-radius: 8px;
  z-index: 5;
}
.ego-form__field.--select-react:hover {
  border-color: #3A00D5;
}
.ego-form__field.--select-react:focus {
  border-color: #3A00D5;
  box-shadow: 0px 0px 0px 4px rgba(76, 13, 224, 0.2);
  outline: none;
  outline-color: #000000;
}
.ego-form__field.--select-react:focus + label {
  position: absolute;
  top: 7px;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0em;
}
.ego-form__field.--select-react .select-color, .ego-form__field.--select-react .select-version, .ego-form__field.--select-react .select-model, .ego-form__field.--select-react .select-year, .ego-form__field.--select-react .select-transmission {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
  color: #454545;
}
.ego-form__field.--select-react .select-color span, .ego-form__field.--select-react .select-version span, .ego-form__field.--select-react .select-model span, .ego-form__field.--select-react .select-year span, .ego-form__field.--select-react .select-transmission span {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ego-form__field.--select-react .select-year-ul {
  display: block !important;
  max-height: 200px;
  overflow-y: auto;
}
.ego-form__field.--select-react .circle-color {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid #D8D8D8;
}
.ego-form__field.--select-react .circle-text {
  text-align: left;
  margin-left: 8px;
}
.ego-form__field.--select-react .color-name, .ego-form__field.--select-react .transmission-name {
  width: 100%;
  text-align: left;
  line-height: 37px;
}
.ego-form__field.--select-react .ego-form__field__select-content {
  background-color: #FFFFFF;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
  padding: 8px 0;
  width: 402px;
  margin-left: -3px;
  margin-top: 8px;
}
.ego-form__field.--select-react .ego-form__field__select-content ul {
  display: flex;
  flex-direction: column;
}
.ego-form__field.--select-react .ego-form__field__select-content ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 38px;
  padding: 0 16px;
  width: 100%;
  text-align: left;
}
.ego-form__field.--select-react .ego-form__field__select-content ul li:hover {
  background-color: #F7F7F7;
}
@media (max-width: 719px) {
  .ego-form__field.--select-react .ego-form__field__select-content {
    width: auto;
  }
}
.ego-form__field.--select-react:first-child {
  z-index: 6;
}
.ego-form__field__toggle-visibility {
  position: absolute;
  z-index: 1;
  right: calc(1.1rem - 5px);
  top: 11px;
  border: 0;
  background: none;
  outline: none;
  padding: 4px;
  margin: 0 auto;
  cursor: pointer;
}
.ego-form__field__toggle-visibility .show {
  width: 24px;
  display: none;
}
.ego-form__field__toggle-visibility .hide {
  width: 24px;
  display: block;
  margin-top: -3px;
}
.ego-form__field__toggle-visibility.--hide .hide {
  display: none;
}
.ego-form__field__toggle-visibility.--hide .show {
  display: block;
}
.ego-form__field__instruction {
  display: block;
  padding: 0 1rem;
  margin: 0;
  text-align: center;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.875;
  letter-spacing: normal;
}
.ego-form__field__search {
  position: relative;
}
.ego-form__field__search .placeholder {
  position: absolute;
  inset: 0;
  width: 100%;
  padding: 0.8rem;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normal;
  font-family: "Switzer", sans-serif;
  color: #E8E8E8;
  pointer-events: none;
}
.ego-form__field__search .placeholder svg {
  display: inline-block;
  margin-left: 5px;
}
.ego-form__field__search .placeholder svg * {
  fill: #E8E8E8;
}
.ego-form__field__search input {
  text-align: center;
  width: 100%;
  padding: 0.8rem;
  border: 2px solid #E8E8E8;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normal;
  font-family: "Switzer", sans-serif;
}
.ego-form__field__search input::-webkit-input-placeholder {
  color: transparent;
}
.ego-form__field__search input::-moz-placeholder {
  color: transparent;
}
.ego-form__field__search input:-moz-placeholder {
  color: transparent;
}
.ego-form__field__search input:focus + .placeholder, .ego-form__field__search input:not(:placeholder-shown) + .placeholder {
  display: none;
}
.ego-form__field__search .empty {
  display: none;
  margin-bottom: 0;
  padding: 1rem;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normal;
  color: #E8E8E8;
}
.ego-form__field__search .empty.--show {
  display: block;
}
.ego-form__footer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
@media (max-width: 575px) {
  .ego-form__field__input, .ego-form__field__textarea, .ego-form__field__select, .ego-form__field__radio-label, .ego-form__field__file {
    padding: 1.13rem 0.9rem 0.4rem 0.9rem;
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: normal;
    letter-spacing: normal;
  }
  .ego-form__field__textarea {
    padding-top: 0.8rem;
  }
  .ego-form__field.--textarea textarea {
    margin-top: 0.8rem;
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: normal;
    letter-spacing: normal;
  }
  .ego-form__field.--textarea .ego-form__field__error {
    min-height: 48px;
  }
  .ego-form__field.--textarea .ego-form__field__footer {
    grid-template-columns: auto 80px;
  }
  .ego-form__field.--textarea .ego-form__field__footer .ego-form__field__length-counter {
    font-size: 10px;
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 1;
    letter-spacing: normal;
  }
  .ego-form__field[data-type=file] .ego-form__field__file {
    padding: 0.765rem 0.9rem 0.765rem 0.9rem;
  }
  .ego-form__field.--optional::after {
    font-size: 10px;
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 1;
    letter-spacing: normal;
    top: 1.25rem;
    right: 1rem;
  }
  .ego-form__field__label {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: normal;
    letter-spacing: normal;
    top: 12px;
  }
  .ego-form__field__instruction {
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.4285714286;
    letter-spacing: normal;
    margin-top: 8px;
  }
  .ego-form__message p {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.7777777778;
    letter-spacing: normal;
  }
}

.ego-modal {
  display: none;
  position: fixed;
  z-index: 10;
  inset: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: 0;
  background-color: rgba(0, 0, 0, 0.8);
  overflow-y: auto;
}
.ego-modal__wrapper {
  display: grid;
  align-items: flex-start;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}
.ego-modal__box {
  position: relative;
  top: 54px;
  display: block;
  margin: 0 auto 80px;
  width: 100%;
  max-width: 700px;
  background-color: #fff;
  border-radius: 16px;
  cursor: default;
  transition: top 0.2s ease-in-out;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.3333333333;
  letter-spacing: normal;
}
.ego-modal__box.--small {
  max-width: 400px;
}
.ego-modal__box .padding-24 {
  padding: 24px;
}
.ego-modal__box__body {
  position: relative;
}
.ego-modal__box__body--page {
  padding: 24px;
  display: none;
}
.ego-modal__box__body--page.--active {
  display: block;
}
.ego-modal__box__body--page .ego-modal__box__close-btn {
  position: absolute;
  top: 25px;
  right: 25px;
  border: 0;
  background: none;
  outline: none;
  padding: 5px;
  margin: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ego-modal__box__body--page .ego-modal__box__close-btn .times {
  width: 14px;
  height: 14px;
}
.ego-modal__box__body--page .ego-modal__box__close-btn .times * {
  transition: stroke 0.2s ease-in-out;
  stroke: #3A00D5;
}
.ego-modal__box__body--page .ego-modal__box__close-btn:hover .times * {
  stroke: #1A0060;
}
.ego-modal__box__body--page .ego-modal__box__back-btn {
  position: absolute;
  top: 25px;
  left: 25px;
  border: 0;
  background: none;
  outline: none;
  padding: 5px;
  margin: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ego-modal__box__body--page .ego-modal__box__back-btn .chevron-left {
  width: 8px;
  height: 14px;
}
.ego-modal__box__body--page .ego-modal__box__back-btn .chevron-left * {
  transition: stroke 0.2s ease-in-out;
  stroke: #3A00D5;
}
.ego-modal__box__body--page .ego-modal__box__back-btn:hover .chevron-left * {
  stroke: #1A0060;
}
.ego-modal__box__content-header {
  text-align: center;
  margin-bottom: 16px;
}
.ego-modal__box__content-header .icon {
  margin-bottom: 16px;
}
.ego-modal__box__content-header p {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714286;
  letter-spacing: normal;
  color: #454545;
  margin: 1rem 0;
}
.ego-modal__content-block {
  margin-bottom: 30px;
}
.ego-modal.--open .ego-modal__box {
  top: 44px;
}
@media (max-width: 719px) {
  .ego-modal {
    padding: 0;
  }
  .ego-modal__wrapper {
    align-items: end;
  }
  .ego-modal__box {
    margin: 80px auto 0;
    bottom: 28px;
    top: auto;
  }
  .ego-modal.--open .ego-modal__box {
    top: auto;
  }
}

h1 {
  font-size: 48px;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.1666666667;
  letter-spacing: -0.02em;
}
@media (max-width: 719px) {
  h1 {
    font-size: 32px;
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.25;
    letter-spacing: -0.02em;
  }
}

h2 {
  font-size: 32px;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: -0.02em;
}
@media (max-width: 719px) {
  h2 {
    font-size: 26px;
    font-size: 1.625rem;
    font-weight: 700;
    line-height: 1.3076923077;
    letter-spacing: -0.02em;
  }
}

h3 {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: normal;
}
h3 strong {
  font-weight: 700;
}

h4 {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.02em;
  margin-bottom: 1rem;
}
h4.--bottomline {
  padding-bottom: 24px;
  margin-bottom: 34px;
  border-bottom: 1px solid #DEDEDF;
}

.--book {
  font-weight: 600;
}

.--title-l {
  font-size: 30px;
  font-size: 1.875rem;
  font-weight: 400;
  line-height: 1.1333333333;
  letter-spacing: 0em;
}

.--title-m {
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.1666666667;
  letter-spacing: 0em;
}

.--title-s {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0em;
}

.--subtitle {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.--text-l {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5555555556;
  letter-spacing: 0em;
}

.--text-m {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0em;
}

.--text-s {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714286;
  letter-spacing: 0em;
}

.--text-xs {
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0em;
}

.--link-s {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.4285714286;
  letter-spacing: 0em;
}

.--link-xs {
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0em;
}

.plan-selector {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.plan-selector__button {
  cursor: pointer;
  text-align: left;
  width: 100%;
  min-height: 77px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #A7A7A8;
  background-color: transparent;
  transition: border-color 0.2s ease-in-out;
}
.plan-selector__button--grid {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 10px;
  height: 100%;
}
.plan-selector__button--check {
  position: relative;
  top: 1px;
  background-color: #FFFFFF;
  margin: 0 6px 0 0;
  font: inherit;
  color: currentColor;
  width: 24px;
  height: 24px;
  border: 2px solid #A7A7A8;
  border-radius: 0.4em;
  transform: translateY(-0.075em);
  display: inline-grid;
  place-content: center;
  vertical-align: middle;
  transition: all 0.2s ease-in-out;
}
.plan-selector__button--check::before {
  content: "";
  width: 0.8em;
  height: 0.8em;
  transform: scale(0);
  box-shadow: inset 1em 1em #FFFFFF;
  background-color: CanvasText;
  clip-path: polygon(18% 41%, 5% 55%, 37% 84%, 100% 20%, 87% 7%, 37% 58%);
  transition: all 0.2s ease-in-out;
}
.plan-selector__button label {
  flex-grow: 1;
  text-align: left;
}
.plan-selector__button label .title {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.125;
  letter-spacing: normal;
  transition: color 0.2s ease-in-out;
}
.plan-selector__button label .price {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: normal;
}
.plan-selector__button label .price sub {
  font-size: 10px;
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: normal;
}
.plan-selector__button .toggle-description {
  align-self: flex-start;
}
.plan-selector__button--description {
  padding-top: 10px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s linear;
}
.plan-selector__button--description p {
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: normal;
}
.plan-selector__button--description p:not(::last-child) {
  margin-bottom: 1rem;
}
.plan-selector__button:hover {
  border-color: #3A00D5;
}
.plan-selector__button:hover .plan-selector__button--check {
  border-color: #3A00D5;
  background-color: #D2C7EF;
}
.plan-selector__button:hover .plan-selector__button--check::before {
  box-shadow: inset 1em 1em #D2C7EF;
}
.plan-selector__button.--hidden {
  display: none;
}
.plan-selector__button.--selected {
  border-color: #3A00D5;
}
.plan-selector__button.--selected .plan-selector__button--check {
  background-color: #3A00D5;
  border-color: #3A00D5;
}
.plan-selector__button.--selected .plan-selector__button--check::before {
  transform: scale(1.2);
}
.plan-selector__button.--selected label .title {
  color: #3A00D5;
}

#financeModal .plan-selector {
  margin: 32px 0 24px;
}
#financeModal .plan-data {
  display: none;
  margin-bottom: 16px;
}
#financeModal .plan-data.--show {
  display: block;
}
#financeModal .plan-data__item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  row-gap: 8px;
  padding: 8px 0;
}
#financeModal .plan-data__item:not(:last-child) {
  border-bottom: 1px solid #E8E8E8;
}
#financeModal .plan-data__item--title {
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 2.1666666667;
  letter-spacing: normal;
}
#financeModal .plan-data__item--value {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5714285714;
  letter-spacing: normal;
}
#financeModal .plan-tyc {
  margin-top: 24px;
}
#financeModal .plan-tyc__text {
  font-size: 10px;
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 2;
  letter-spacing: normal;
  transition: height 0.2s ease-in-out;
}
#financeModal .plan-tyc__text p:not(:last-child) {
  margin-bottom: 1.2rem;
}
#financeModal .plan-tyc__text.--ellipsis {
  height: 4rem;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.header {
  position: fixed;
  top: 0;
  z-index: 10;
  background-color: #FFFFFF;
  border-bottom: 1px solid #E8E8E8;
  padding: 21px 0px;
  width: 100%;
}
.header__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 9;
}
.header__logo-container {
  max-width: 170px;
}
.header__logo-container a img {
  display: block;
  max-width: 100%;
  height: auto;
}
@media (max-width: 900px) {
  .header__primary-nav {
    display: none;
  }
}
.header__primary-nav ul {
  display: flex;
  column-gap: 40px;
  align-items: center;
}
.header__primary-nav ul li {
  display: flex;
  column-gap: 16px;
  align-items: center;
}
.header__primary-nav ul li .btn-small {
  padding: 8px 20px;
  font-weight: 700;
  min-height: 40px;
}
.header__menu-button {
  display: none;
}
@media (max-width: 900px) {
  .header__menu-button {
    display: block;
  }
}
.header__menu-button .header-toggler {
  font-weight: 700;
  position: relative;
  z-index: 3;
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  margin-left: 1.5rem;
  display: flex;
  align-items: center;
}
.header__menu-button .header-toggler:hover, .header__menu-button .header-toggler:focus, .header__menu-button .header-toggler:active {
  outline: none;
}
.header__menu-button .header-toggler .toggler-text {
  font-size: 0.7rem;
  color: #000000;
}
.header__menu-button .header-toggler .toggler-text:before, .header__menu-button .header-toggler .toggler-text:after {
  transition: all 0.4s ease-in-out;
}
.header__menu-button .header-toggler .toggler-text:before {
  content: "";
  visibility: visible;
  opacity: 1;
  position: absolute;
}
.header__menu-button .header-toggler .toggler-text:after {
  content: "";
  visibility: hidden;
  opacity: 0;
}
@media (max-width: 900px) {
  .header__menu-button .header-toggler .toggler-text {
    display: none;
  }
}
@media (max-width: 900px) {
  .header__menu-button .header-toggler.active .toggler-text {
    display: block;
  }
}
.header__menu-button .header-toggler .toggler-icon {
  width: 20px;
  height: 20px;
  position: relative;
  margin-left: 0.5rem;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
}
.header__menu-button .header-toggler .toggler-icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #000000;
  border-radius: 5px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}
.header__menu-button .header-toggler .toggler-icon span:nth-child(1) {
  top: 3px;
}
.header__menu-button .header-toggler .toggler-icon span:nth-child(2) {
  top: 8.5px;
  height: 2.5px;
}
.header__menu-button .header-toggler .toggler-icon span:nth-child(3) {
  top: 15px;
  height: 2px;
}
.header__menu-button .header-toggler.active .toggler-text:before {
  visibility: hidden;
  opacity: 0;
}
.header__menu-button .header-toggler.active .toggler-text:after {
  visibility: visible;
  opacity: 1;
}
.header__menu-button .header-toggler.active .toggler-icon span:nth-child(1) {
  top: 8.5px;
  transform: rotate(135deg);
}
.header__menu-button .header-toggler.active .toggler-icon span:nth-child(2) {
  opacity: 0;
}
.header__menu-button .header-toggler.active .toggler-icon span:nth-child(3) {
  top: 8.5px;
  transform: rotate(-135deg);
}
.header__secondary-nav {
  position: fixed;
  z-index: 2;
  right: 0;
  top: 0;
  bottom: 0;
  min-width: 20rem;
  width: 100%;
  background-color: #FFFFFF;
  visibility: hidden;
  transform: translateX(100%);
  transition: all 300ms ease-in-out;
}
@media (max-width: 900px) {
  .header__secondary-nav.open {
    visibility: visible;
    transform: translateX(0);
    height: 100vh;
    min-width: auto;
    transition: all 0.2s ease-in-out;
  }
  .header__secondary-nav__list {
    position: absolute;
    top: 4.5rem;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #FFFFFF;
    border-top: 1px solid #E8E8E8;
  }
  .header__secondary-nav__list ul {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    row-gap: 30px;
  }
  .header__secondary-nav__list ul:last-child {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #E8E8E8;
  }
  .header__secondary-nav__list ul:last-child .primary-btn {
    display: block;
    width: 100%;
  }
  .header__secondary-nav__list ul li {
    padding: 0 16px;
  }
}

.footer {
  background-color: #191919;
  color: #FFFFFF;
  padding-top: 64px;
  padding-bottom: 64px;
}
.footer-grid {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
}
@media (min-width: 720px) {
  .footer-grid {
    grid-template-columns: repeat(5, 1fr 16px) 1fr;
  }
}
@media (min-width: 1024px) {
  .footer-grid {
    grid-template-columns: repeat(11, 1fr 16px) 1fr;
  }
}
.footer-grid__line {
  background-color: #454545;
  height: 1px;
  grid-column: 1/-1;
}
.footer-grid__line__top {
  grid-row: 3;
}
.footer-grid__line__middle {
  display: none;
}
@media (max-width: 1024px) {
  .footer-grid__line__middle {
    display: block;
    grid-row: 5;
  }
}
.footer-grid__line__bottom {
  grid-row: 5;
}
@media (max-width: 1024px) {
  .footer-grid__line__bottom {
    grid-row: 7;
  }
}
.footer-grid__logo {
  grid-row: 1;
  padding-bottom: 40px;
}
.footer-grid .--text-s.vehicle-title {
  color: #A7A7A8;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #454545;
}
.footer-grid .--text-s.vehicle-title.no-title {
  min-height: 40px;
}
.footer-grid__items {
  grid-row: 2;
  grid-column: 1/-1;
  padding-bottom: 30px;
  display: grid;
  grid-template-columns: repeat(11, 1fr 16px) 1fr;
}
.footer-grid__items a {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5555555556;
  letter-spacing: normal;
}
.footer-grid__items.footer-mobile {
  display: none;
}
.footer-grid__items.footer-desktop .link-column {
  display: flex;
  flex-direction: column;
}
.footer-grid__items.footer-desktop .link-column a, .footer-grid__items.footer-desktop .link-column span {
  min-height: 28px;
}
.footer-grid__items.footer-desktop .link-column.col-1 {
  grid-column: 1/span 3;
}
.footer-grid__items.footer-desktop .link-column.col-1:has(.no-title), .footer-grid__items.footer-desktop .link-column.col-1:has(.long-line) {
  grid-column: 1/span 4;
}
.footer-grid__items.footer-desktop .link-column.col-2 {
  grid-column: 5/span 3;
}
.footer-grid__items.footer-desktop .link-column.col-2:has(.no-title), .footer-grid__items.footer-desktop .link-column.col-2:has(.long-line) {
  grid-column: 5/span 4;
}
.footer-grid__items.footer-desktop .link-column.col-3 {
  grid-column: 9/span 3;
}
.footer-grid__items.footer-desktop .link-column.col-3:has(.no-title), .footer-grid__items.footer-desktop .link-column.col-3:has(.long-line) {
  grid-column: 9/span 4;
}
.footer-grid__items.footer-desktop .link-column.col-4 {
  grid-column: 13/span 3;
}
.footer-grid__items.footer-desktop .link-column.col-4:has(.no-title), .footer-grid__items.footer-desktop .link-column.col-4:has(.long-line) {
  grid-column: 13/span 4;
}
.footer-grid__items.footer-desktop .link-column.col-5 {
  grid-column: 17/span 3;
}
.footer-grid__items.footer-desktop .link-column.col-5:has(.no-title), .footer-grid__items.footer-desktop .link-column.col-5:has(.long-line) {
  grid-column: 17/span 4;
}
.footer-grid__items.footer-desktop .link-column.col-6 {
  grid-column: 21/span 3;
}
.footer-grid__items.footer-desktop .link-column.col-6:has(.no-title), .footer-grid__items.footer-desktop .link-column.col-6:has(.long-line) {
  grid-column: 21/span 4;
}
@media (max-width: 1150px) {
  .footer-grid__items {
    padding-bottom: 40px;
  }
  .footer-grid__items.footer-mobile {
    display: grid;
  }
  .footer-grid__items.footer-mobile .link-column {
    grid-column: 1/-1;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 16px;
    margin-top: 40px;
  }
  .footer-grid__items.footer-mobile .link-column:nth-child(1) {
    margin-top: 0;
  }
  .footer-grid__items.footer-mobile .link-column .vehicle-title {
    grid-column: 1/-1;
  }
  .footer-grid__items.footer-mobile .link-column a, .footer-grid__items.footer-mobile .link-column span {
    min-height: 28px;
  }
  .footer-grid__items.footer-desktop {
    display: none;
  }
}
@media (max-width: 600px) {
  .footer-grid__items.footer-mobile .link-column {
    grid-template-columns: 1fr 1fr;
  }
}
.footer-grid__text {
  grid-row: 4;
  grid-column: 1/span 7;
  align-self: start;
  padding-bottom: 50px;
  padding-top: 40px;
}
.footer-grid__text .--title-s {
  margin-bottom: 20px;
}
.footer-grid__text .inline-text {
  display: inline-block;
}
@media (max-width: 1024px) {
  .footer-grid__text {
    grid-column: 1/-1;
  }
}
@media (max-width: 600px) {
  .footer-grid__text {
    max-width: 89%;
  }
}
.footer-grid__social {
  grid-row: 4;
  grid-column: 17/-1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-bottom: 50px;
  padding-top: 40px;
}
.footer-grid__social p {
  margin-bottom: 16px;
}
.footer-grid__social-buttons {
  display: flex;
}
.footer-grid__social-buttons a {
  margin-left: 16px;
}
.footer-grid__social-buttons a:nth-child(1) {
  margin-left: 0;
}
.footer-grid__social-buttons a img {
  width: 24px;
}
@media (max-width: 1024px) {
  .footer-grid__social {
    grid-column: 1/-1;
    grid-row: 6;
    align-items: flex-start;
  }
}
@media (max-width: 600px) {
  .footer-grid__social {
    max-width: 89%;
  }
}
.footer-grid__terms {
  grid-row: 7;
  grid-column: 1/-1;
  padding-top: 32px;
  display: flex;
  justify-content: space-between;
}
.footer-grid__terms .copyright {
  color: #D8D8D8;
  display: flex;
  align-items: flex-start;
}
.footer-grid__terms .links a {
  margin-left: 24px;
}
@media (max-width: 1024px) {
  .footer-grid__terms {
    grid-row: 8;
    padding-bottom: 40px;
  }
}
@media (max-width: 800px) {
  .footer-grid__terms {
    flex-direction: column;
    justify-content: flex-start;
  }
  .footer-grid__terms .links {
    margin-top: 32px;
  }
  .footer-grid__terms .links a:nth-child(1) {
    margin-left: 0;
  }
  .footer-grid__terms .links a:nth-child(2) {
    margin-left: 20;
  }
}
@media (max-width: 600px) {
  .footer-grid__terms .copyright {
    max-width: 89%;
    flex-direction: column;
  }
}

.whatsapp-icon {
  position: fixed;
  z-index: 9999;
  font-size: medium;
  line-height: normal;
  margin: 0;
  padding: 0;
  background-color: white;
  border-radius: 50%;
  width: 3.5em;
  height: 3.5em;
  bottom: 2em;
  right: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 1px 0 #02ff17, 0 3px 15px 0 rgba(25, 150, 0, 0.3);
  transition: all 0.2s ease-in-out;
}
.whatsapp-icon svg {
  width: 55%;
}
.whatsapp-icon svg path {
  fill: #51C85D;
}

.whatsapp-icon:hover {
  box-shadow: 0 0 1px 0 #02ff17, 0 3px 15px 0 rgba(25, 150, 0, 0.8);
}