@use 'sass:math';

.ego-form {
    width: 100%;
    margin: 0 auto;

    $form: &;

    &__field {
        width: 100%;
        position: relative;
        margin-bottom: 40px;
        background-color: transparent;
        z-index: 4;

        @mixin active-label {
            position: absolute;
            top: 7px;
            @include font-size(12, 12, 400, 0);
        }

        @mixin active-field {
            border-color: $primary-color;
            box-shadow: 0px 0px 0px 4px rgba(76, 13, 224, 0.2);
            outline: none;
            outline-color: $black;
        }

        @mixin hidden-input {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
        }

        $field: &;

        &__select {
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            padding-right: 45px;

            &::-ms-expand {
                display: none;
            }
        }

        &__input,
        &__select,
        &__radio-label,
        &__file {
            display: block;
            position: relative;
            z-index: 0;
            border: 2px solid #A7A7A8;
            background-color: $white;
            border-radius: 8px;
            padding: 18px 16px 6px 16px;
            height: 48px;
            width: 100%;
            color:$black;
            @include font-size(16, 16, 400, 0);
            font-family: 'ToyotaType', sans-serif;
            transition: all $timming ease-in-out;
            background-repeat: no-repeat;
            background-position: center right 16px;

            &:hover {
                border-color: $primary-color;
            }

            &:focus {
                @include active-field;
            }

            &:focus + label {
                @include active-label;
            }
        }

        &__label {
            pointer-events: none;
            position: absolute;
            top: 15px;
            left: 0px;
            padding-left: 17px;
            @include font-size(16, 16, 400, 0);
            color: $gray;
            user-select: none;
            cursor: text;
            width: 100%;
            height: 100%;
            text-align: left;
            transition: all $timming ease-in-out;
        }

        &__textarea {
            padding-top: 1.4rem;
            height: 100%;
            resize: none;

            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                font-style: italic;
            }
            &::-moz-placeholder { /* Firefox 19+ */
                font-style: italic;
            }
            &:-moz-placeholder { /* Firefox 18- */
                font-style: italic;
            }
        }

        &.--checkbox {
            cursor: pointer;

            #{$field}__label {
                pointer-events: all;
                position: static;
                padding: 0;
                @include font-size(16, 24);
            }

            #{$field}__checkbox {
                appearance: none;
                background-color: $white;
                margin: 0 6px 0 0;
                font: inherit;
                color: currentColor;
                width: 1.5em;
                height: 1.5em;
                border: 2px solid #A7A7A8;
                border-radius: 0.4em;
                transform: translateY(-0.075em);
                display: inline-grid;
                place-content: center;
                vertical-align: middle;
                transition: all $timming ease-in-out;

                &::before {
                    content: "";
                    width: 0.8em;
                    height: 0.8em;
                    transform: scale(0);
                    box-shadow: inset 1em 1em $white;
                    background-color: CanvasText;
                    clip-path: polygon(18% 41%, 5% 55%, 37% 84%, 100% 20%, 87% 7%, 37% 58%);
                    transition: all $timming ease-in-out;
                }

                &:hover {
                    border-color: $primary-color;
                    background-color: $primary-color-light;

                    &::before {
                        box-shadow: inset 1em 1em $primary-color-light;
                    }
                }

                &:focus {
                    box-shadow: 0px 0px 0px 4px rgba(76, 13, 224, 0.2);
                    outline: none;
                }

                &:checked {
                    background-color: $primary-color;
                    border-color: $primary-color;

                    &::before {
                        transform: scale(1);
                    }
                }
            }

            &.--has-error {
                #{$field}__checkbox {
                    border-color: $danger-color !important;

                    &:checked {
                        background-color: $danger-color;
                    }
                }
            }

            #{$field}__error {
                position: static;
                margin: auto;
                display: table;
                margin-top: 10px;
            }
        }

        &.--radio {
            cursor: pointer;

            #{$field}__label {
                display: block;
                pointer-events: all;
                position: static;
                padding: 0;
                @include font-size(16, 24);

                &:not(:last-child) {
                    margin-bottom: 32px;
                }
            }

            #{$field}__radio {
                appearance: none;
                background-color: $white;
                margin: 0 6px 0 0;
                font: inherit;
                color: currentColor;
                width: 1.5em;
                height: 1.5em;
                border: 2px solid #A7A7A8;
                border-radius: 50%;
                transform: translateY(-0.075em);
                display: inline-grid;
                place-content: center;
                vertical-align: middle;
                transition: all $timming ease-in-out;

                &::before {
                    content: "";
                    width: 0.6em;
                    height: 0.6em;
                    border-radius: 50%;
                    transform: scale(0);
                    background-color: $white;
                    transition: transform $timming ease-in-out;
                }

                &:hover {
                    border-color: $primary-color;
                    background-color: $primary-color-light;

                    &::before {
                        box-shadow: inset 1em 1em $primary-color-light;
                    }
                }

                &:focus {
                    box-shadow: 0px 0px 0px 4px rgba(76, 13, 224, 0.2);
                    outline: none;
                }

                &:checked {
                    background-color: $primary-color;
                    border-color: $primary-color;

                    &::before {
                        transform: scale(1);
                    }
                }
            }

            &.--inline-radio {
                display: flex;
                flex-flow: row wrap;
                row-gap: 7px;
                column-gap: 3px;
                margin-bottom: 15px;

                #{$field}__label {
                    display: inline-block;
                    width: auto;
                    margin: 4px 0;
                }

                #{$field}__radio {
                    &::before {
                        display: none;
                    }

                    &:checked {
                        border-color: $white;
                        box-shadow: 0px 0px 0px 4px $primary-color;
                    }
                }
            }

            &.--has-error {
                #{$field}__checkbox {
                    border-color: $danger-color !important;

                    &:checked {
                        background-color: $danger-color;
                    }
                }
            }

            #{$field}__error {
                position: static;
                margin: auto;
                display: table;
                margin-top: 10px;
            }
        }

        &.--password {
            input {
                padding-right: 52px;
            }
        }

        &.--file {
            #{$field}__file {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                gap: 10px;
                align-items: center;
                padding: 1.3rem 1rem 1.3rem 1rem;
                cursor: pointer;

                .attachment-control {
                    display: flex;
                    align-items: center;
                    min-height: 22px;
                }

                .attachment-file-info {
                    display: grid;
                    grid-template-columns: auto minmax(60px, 1fr);
                    align-items: center;
                    gap: 10px;

                    .attachment-file-name {
                        @include font-size(16, 16);
                        text-decoration: underline;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }

                    .attachment-file-size {
                        @include font-size(12, 12);
                        color: $light-gray;
                        min-width: 60px;

                        i {
                            color: $primary-color;
                            text-transform: uppercase;
                        }

                        &:empty {
                            &:before {
                              content: attr(data-placeholder);
                            }
                        }
                    }
                }

                .icon__clip {
                    height: 1rem;
                }

                .attachment-remove {
                    display: none;
                    cursor: pointer;
                    border: 0;
                    background: none;
                    outline: none;
                    padding: 0;
                    margin: 0 0 0 auto;
                    font-size: 23px;
                    line-height: .8;
                }
            }

            &.--has-file {
                #{$field}__file {
                    @include active-field;

                    .icon__clip {
                        display: none;
                    }
      
                    .attachment-remove {
                        display: block;
                    }
                }
            }
    
            input {
                @include hidden-input;
            }
        }

        &.--disabled {
            pointer-events: none;
            opacity: 0.4;
        }

        &__error {
            opacity: 0;
            color: $danger-color;
            @include font-size(14, 14, 400, 0);
            text-align: left;
            position: absolute;
            left: 0;
            top: 55px;
            display: flex;
            align-items: self-end;
            background-repeat: no-repeat;
            padding-left: 20px;
            transition: opacity $timming ease-in-out;

            img {
                margin-right: 9px;
            }
            
            strong {
                font-weight: 400;
            }

            &.--active {
                opacity: 1;
            }
        }

        &__warning {
            color: $gray;
            @include font-size(14, 14, 400, 0);
            text-align: left;
            position: absolute;
            left: 0;
            top: 55px;
            display: flex;
            background-repeat: no-repeat;
            padding-left: 20px;
            height: 16px;
            transition: opacity $timming ease-in-out;

            img {
                margin-right: 9px;
            }
            
            strong {
                font-weight: 400;
            }
        }

        &.--filled {
            #{$field}__input:not(:focus),
            #{$field}__select:not(:focus),
            &.--textarea:not(:focus) {
                border-color: $gray;
            }
            #{$field}__label {
                @include active-label;
            }
        }

        &.--optional,
        &.--custom-assistive {
            &::after {
                @include font-size(12, 12, 400);

                content: 'OPTIONAL';
                position: absolute;
                right: 1rem;
                top: 1.1rem;
                color: $black;
                pointer-events: none;
                transition: opacity $timming ease-in-out;

                @include device-md {
                    @include font-size(10, 10, 400);
                    right: 1rem;
                    top: 1.3rem;
                }
            }

            &.--filled {
                &::after {
                    opacity: 0;
                }
            }
        }

        &.--optional {
            &::after {
                content: 'OPTIONAL';
            }
        }

        &.--custom-assistive {
            $value: attr(data-assistive);
            &::after {
                content: $value;
            }
        }


        &.--has-error {
            #{$field}__input,
            #{$field}__select,
            #{$field}__file,
            &.--textarea {
                background-color: $white;
                border-color: $danger-color !important;
            }

            #{$field}__error {
                opacity: 1;
            }

            #{$field}__warning {
                opacity: 0;
            }

            &.--optional:after {
                display: none !important;
            }
        }

        &.--full-height {
            height: 100%;
        }

        &.--textarea {
            display: flex;
            flex-direction: column;
            width: 100%;
            border: 2px solid #A7A7A8;
            background-color: $white;
            border-radius: 8px;
            transition: all $timming ease-in-out;

            &:hover {
                border-color: $black;
            }

            &:focus-within {
                @include active-field;
                outline-color: $black;
            }

            textarea {
                display: block;
                font-family: 'ToyotaType', sans-serif;
                @include font-size(16);
                padding: 0;
                margin: 1rem;
                border: 0;
                background: transparent;
                outline: none !important;
                @include custom-scrollbars;
            }

            &.--big {
                min-height: 280px;
            }

            #{$field}__label {
                opacity: .5;
                font-style: italic;
            }

            #{$field}__error {
                inset: 1px 1px auto auto;
                height: auto;
                width: fit-content;
                background-color: none;
                max-width: none;
                min-height: 70px;
                max-height: 50%;
            }

            #{$field}__footer {
                position: relative;
                height: auto;
                width: calc(100% - 2px);
                max-width: none;
                min-height: 50px;
                max-height: 50%;
                padding: 0 1rem 1rem;
                display: grid;
                grid-template-columns: auto 95px;
                align-items: end;
                gap: 20px;

                #{$field}__attachment {
                    min-width: 0;
                    position: relative;
                    z-index: 1;

                    .attachment-label {
                        display: flex;
                        flex-flow: row nowrap;
                        justify-content: flex-start;
                        gap: 10px;
                        align-items: center;
                        cursor: pointer;

                        .attachment-control {
                            display: flex;
                            align-items: center;
                            min-height: 22px;
                        }
                        
                        .attachment-file-info {
                            min-width: 0;
                            display: flex;
                            align-items: center;
                            gap: 10px;

                            .attachment-file-name {
                                @include font-size(16, 16);
                                max-width: 100%;
                                text-decoration: underline;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                            }

                            .attachment-file-size {
                                @include font-size(12, 12);
                                color: $light-gray;
                                min-width: 60px;

                                i {
                                    color: $primary-color;
                                    text-transform: uppercase;
                                }
                                
                                &:empty {
                                    &:before {
                                      content: attr(data-placeholder);
                                    }
                                }
                            }
                        }
    
                        .icon__clip {
                            display: block;
                            width: 8px;
                            height: 16px;
                        }

                        .attachment-remove {
                            display: none;
                            cursor: pointer;
                            border: 0;
                            background: none;
                            outline: none;
                            padding: 0;
                            margin: 0 0 0 auto;
                            font-size: 23px;
                            line-height: .6;
                        }
                    }

                    &.--has-file {
                        .icon__clip {
                            display: none;
                        }

                        .attachment-remove {
                            display: block;
                        }
                    }

                    input {
                        width: 0.1px;
                        height: 0.1px;
                        opacity: 0;
                        overflow: hidden;
                        position: absolute;
                        z-index: -1;
                    }
                }
    
                #{$field}__length-counter {
                    position: relative;
                    z-index: 1;
                    text-align: right;
                    margin-left: auto;
                    @include font-size(12, 15);
                    color: $black;
                }

                .ego-form__progress-bar {
                    content: '';
                    position: absolute;
                    inset: 0 auto 0 0;
                    width: 0%;
                    background-color: yellow;
                }
            }
        }

        &.--select {
            display: block;
            position: relative;
            z-index: 0;
            height: 48px;
            width: 100%;
            color:$black;
            @include font-size(16, 16, 400, 0);
            font-family: 'ToyotaType', sans-serif;
            transition: all $timming ease-in-out;
            cursor: default;
            border: 2px solid #A7A7A8;
            border-radius: 8px;
            z-index: 5;

            #{$field}__label {
                top: 13px;
            }

            &:hover {
                border-color: $primary-color;
            }

            &:focus,
            &.--open {
                @include active-field;
                z-index: 6;
            }

            &:focus + label,
            &.--open + label {
                @include active-label;
            }

            &.--filled {
                border-color: $gray;

                #{$field}__label {
                    top: 5px;
                }
            }

            &.--has-error {
                border-color: $danger-color !important;
            }

            .caret {
                content: '';
                display: inline-block;
                width: 22px;
                height: 22px;
                background: url('../images/dropdown-arrow.svg') no-repeat center center;
                background-size: contain;
            }
            
            #{$field}__select-display {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px 16px;
                border-radius: 8px;
                transition: all $timming ease-in-out;
                color: $gray;

                .value-placeholder {
                    @include font-size(16, 16, 400);
                    margin-top: 10px;
                }
            }

            #{$field}__select-dropdown {
                position: relative;
                z-index: 1;
                background-color: $white;
                box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
                border: 1px solid $light-gray;
                border-radius: 8px;
                padding: 8px 0;
                // width: 402px;
                margin-left: -3px;
                margin-top: 8px;
                max-height: 160px;
                overflow-x: auto;
                pointer-events: none;
                opacity: 0;
                transition: opacity .2s ease-in-out, color .2s ease-in-out, background-color .2s ease-in-out;

                @include custom-scrollbars;

                ul {
                    display: flex;
                    flex-direction: column;

                    li.option {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: 38px;
                        padding: 0 16px;
                        width: 100%;
                        
                        &:not(.--disabled):hover,
                        &:not(.--selected):hover {
                            background-color: #F7F7F7;
                        }

                        &.--selected {
                            background-color: $primary-color !important;
                            color: $white;
                        }

                        &.--disabled {
                            color: $light-gray;
                            pointer-events: none;
                            cursor: not-allowed;
                        }
                    }

                }
            }

            &.--open {
                #{$field}__select-dropdown {
                    opacity: 1;
                    pointer-events: all;
                }
            }

            &.--drop-up {
                #{$field}__select-dropdown {
                    position: absolute;
                    inset: auto 0 calc(100% + 10px) 0;
                    box-shadow: 0px -4px 8px -2px rgba(16, 24, 40, 0.1), 0px -2px 4px -2px rgba(16, 24, 40, 0.06);
                }
            }
        }

        &.--select-text {
            display: inline-block;
            position: relative;
            z-index: 0;
            width: auto;
            height: 25px;
            margin-bottom: 0;
            padding: 0;
            color: $black;
            @include font-size(16, 16, 400, 0);
            font-family: 'ToyotaType', sans-serif;
            transition: all $timming ease-in-out;
            cursor: pointer;
            border: 0;
            z-index: 5;

            &.--has-error {
                border-color: $danger-color !important;
            }

            .caret {
                content: '';
                display: inline-block;
                width: 22px;
                height: 22px;
                background: url('../images/dropdown-arrow-blue.svg') no-repeat center center;
                background-size: contain;
            }
            
            #{$field}__select-display {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;;
                padding: 0;
                transition: all $timming ease-in-out;
                color: $primary-color;

                .value-placeholder {
                    @include font-size(16, 16, 400);
                    text-decoration: underline;
                    text-underline-offset: 4px;
                }
            }

            #{$field}__select-dropdown {
                position: relative;
                z-index: 1;
                background-color: #FFFFFF;
                box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
                border-radius: 8px;
                padding: 8px 0;
                // width: 402px;
                margin-left: -3px;
                margin-top: 8px;
                max-height: 160px;
                overflow-x: auto;
                pointer-events: none;
                opacity: 0;
                transition: opacity .2s ease-in-out, color .2s ease-in-out, background-color .2s ease-in-out;

                @include custom-scrollbars;

                ul {
                    display: flex;
                    flex-direction: column;

                    li.option {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: 38px;
                        padding: 0 16px;
                        width: 100%;
                        
                        &:not(.--disabled):hover,
                        &:not(.--selected):hover {
                            background-color: #F7F7F7;
                        }

                        &.--selected {
                            background-color: $primary-color !important;
                            color: $white;
                        }

                        &.--disabled {
                            color: $light-gray;
                            pointer-events: none;
                            cursor: not-allowed;
                        }
                    }
                }
            }
            
            &:hover {
                #{$field}__select-display {
                    color: $primary-color-darker;
                }
            }
            
            &.--open {
                z-index: 6;
    
                .caret {
                    transform: rotate(180deg);
                }

                #{$field}__select-dropdown {
                    opacity: 1;
                    pointer-events: all;
                }
            }

            &.--drop-up {
                #{$field}__select-dropdown {
                    position: absolute;
                    inset: auto 0 calc(100% + 10px) 0;
                }
            }
        }

        &.--select:first-child {
            z-index: 6;
        }

        &.--select-react {
            display: block;
            position: relative;
            z-index: 0;
            height: 48px;
            width: 100%;
            color:$black;
            @include font-size(16, 16, 400, 0);
            font-family: 'ToyotaType', sans-serif;
            transition: all $timming ease-in-out;
            cursor: default;
            border: 2px solid #A7A7A8;
            border-radius: 8px;
            z-index: 5;

            &:hover {
                border-color: $primary-color;
            }

            &:focus {
                @include active-field;
            }

            &:focus + label {
                @include active-label;
            }
            
            .select-color, .select-version, .select-model, .select-year {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px 16px;
                border-radius: 8px;
                transition: all $timming ease-in-out;
                color: $gray;

                span {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
            }

            .select-year-ul {
                display: block !important;
                max-height: 200px;
                overflow-y: auto;
            }

            .circle-color {
                width: 22px;
                height: 22px;
                border-radius: 50%;
                border: 1px solid #D8D8D8;
            }

            .circle-text {
                text-align: left;
                margin-left: 8px;
            }

            .color-name {
                width: 100%;
                text-align: left;
                line-height: 37px;
            }

            .ego-form__field__select-content {
                background-color: #FFFFFF;
                box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
                border-radius: 8px;
                padding: 8px 0;
                // display: none;
                width: 402px;
                margin-left: -3px;
                margin-top: 8px;

                ul {
                    display: flex;
                    flex-direction: column;

                    li {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: 38px;
                        padding: 0 16px;
                        width: 100%;
                        text-align: left;
                    }

                    li:hover {
                        background-color: #F7F7F7;
                    }
                }

                @include device-md(){
                    width: auto;
                }
            }
        }

        &.--select-react:first-child {
            z-index: 6;
        }


        &__toggle-visibility {
            position: absolute;
            z-index: 1;
            right: calc(1.1rem - 5px);
            top: 11px;
            border: 0;
            background: none;
            outline: none;
            padding: 4px;
            margin: 0 auto;
            cursor: pointer;

            .show {
                width: 24px;
                display: none;
            }

            .hide {
                width: 24px;
                display: block;
                margin-top: -3px;
            }

            &.--hide {
                .hide {
                    display: none;
                }

                .show {
                    display: block;
                }
            }
        }

        &__instruction {
            display: block;
            padding: 0 1rem;
            margin: 0;
            text-align: center;
            @include font-size(16, 30);
        }

        &__search {
            position: relative;

            .placeholder {
                position: absolute;
                inset: 0;
                width: 100%;
                padding: 0.8rem;
                @include font-size(20);
                font-family: 'Switzer', sans-serif;
                color: $light-gray;
                pointer-events: none;

                svg {
                    display: inline-block;
                    margin-left: 5px;

                    * {
                        fill: $light-gray;
                    }
                }
            }

            input {
                text-align: center;
                width: 100%;
                padding: 0.8rem;
                border: 2px solid $light-gray;
                @include font-size(20);
                font-family: 'Switzer', sans-serif;

                &::-webkit-input-placeholder {
                    color: transparent;
                }
                &::-moz-placeholder {
                    color: transparent;
                }
                &:-moz-placeholder {
                    color: transparent;
                }

                &:focus + .placeholder,
                &:not(:placeholder-shown) + .placeholder {
                    display: none;
                }
            }

            .empty {
                display: none;
                margin-bottom: 0;
                padding: 1rem;
                @include font-size(16);
                color: $light-gray;

                &.--show {
                    display: block;
                }
            }
        }
    }

    &__footer {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    @include device-sm {

        &__field {
            $field: &;

            &__input,
            &__textarea,
            &__select,
            &__radio-label,
            &__file {
                padding: 1.13rem .9rem .4rem .9rem;
                @include font-size(16);
            }

            &__textarea {
                padding-top: .8rem;
            }

            &.--textarea {
                
                textarea {
                    margin-top: 0.8rem;
                    @include font-size(18);
                }

                #{$field}__error {
                    min-height: 48px;
                }

                #{$field}__footer {
                    grid-template-columns: auto 80px;

                    #{$field}__length-counter {
                        @include font-size(10, 10);
                    }
                }
            }

            &[data-type=file] {
                #{$field}__file {
                    padding: .765rem .9rem .765rem .9rem;
                }
            }

            &.--optional {
                &::after {
                    @include font-size(10, 10);
                    top: 1.25rem;
                    right: 1rem;
                }
            }

            &__label {
                @include font-size(16);
                top: 12px;
            }

            &__instruction {
                @include font-size(14, 20);
                margin-top: 8px;
            }
        }

        &__message {
            p {
                @include font-size(18, 32);
            }
        }
    }
}