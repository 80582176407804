.plan-selector {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__button {
        cursor: pointer;
        text-align: left;
        width: 100%;
        min-height: 77px;
        padding: 16px;
        border-radius: 8px;
        border: 1px solid #A7A7A8;
        background-color: transparent;
        transition: border-color $timming ease-in-out;

        $button: &;

        &--grid {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            gap: 10px;
            height: 100%;
        }

        &--check {
            position: relative;
            top: 1px;
            background-color: $white;
            margin: 0 6px 0 0;
            font: inherit;
            color: currentColor;
            width: 24px;
            height: 24px;
            border: 2px solid #A7A7A8;
            border-radius: 0.4em;
            transform: translateY(-0.075em);
            display: inline-grid;
            place-content: center;
            vertical-align: middle;
            transition: all $timming ease-in-out;

            &::before {
                content: "";
                width: 0.8em;
                height: 0.8em;
                transform: scale(0);
                box-shadow: inset 1em 1em $white;
                background-color: CanvasText;
                clip-path: polygon(18% 41%, 5% 55%, 37% 84%, 100% 20%, 87% 7%, 37% 58%);
                transition: all $timming ease-in-out;
            }
        }

        label {
            flex-grow: 1;
            text-align: left;

            .title {
                @include font-size(16, 18, 400);
                transition: color $timming ease-in-out;
            }
            .price {
                @include font-size(20, 24, 700);

                sub {
                    @include font-size(10, 10, 400);
                }
            }
        }

        .toggle-description {
            align-self: flex-start;
        }

        &--description {
            padding-top: 10px;
            max-height: 0;
            overflow: hidden;
            transition: max-height .2s linear;

            p {
                @include font-size(12, 18);

                &:not(::last-child) {
                    margin-bottom: 1rem;
                }
            }
        }

        &:hover {
            border-color: $primary-color;

            #{$button}--check {
                border-color: $primary-color;
                background-color: $primary-color-light;
    
                &::before {
                    box-shadow: inset 1em 1em $primary-color-light;
                }
            }
        }

        &.--hidden {
            display: none;
        }

        &.--selected {
            border-color: $primary-color;

            #{$button}--check {
                background-color: $primary-color;
                border-color: $primary-color;

                &::before {
                    transform: scale(1.2);
                }
            }

            label {
                .title {
                    color: $primary-color;
                }
            }
        }
    }
}

#financeModal {
    .plan-selector {
        margin: 32px 0 24px;
    }

    .plan-data {
        display: none;
        margin-bottom: 16px;
        
        &.--show{
            display: block;
        }

        &__item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            row-gap: 8px;
            padding: 8px 0;

            &:not(:last-child) {
                border-bottom: 1px solid $light-gray;
            }

            &--title {
                @include font-size(12, 26, 700);
            }

            &--value {
                @include font-size(14, 22, 400);
            }
        }
    }

    .plan-tyc {
        margin-top: 24px;

        &__text {
            @include font-size(10, 20, 400);
            transition: height $timming ease-in-out;

            p {
                &:not(:last-child) {
                    margin-bottom: 1.2rem;
                }
            }
            
            &.--ellipsis {
                height: 4rem;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                        line-clamp: 3; 
                -webkit-box-orient: vertical;
            }
        }
    }
}