.footer {
    background-color: $dark-gray;
    color: $white;
    padding-top: 64px;
    padding-bottom: 64px;

    &-grid {
        @include main-grid();
        // grid-template-rows: 6;

        &__line {
            background-color: $gray;
            height: 1px;
            grid-column: 1 / -1;

            &__top {
                grid-row: 3;
            }

            &__middle {
                display: none;
                
                @include rwd(1024) {
                    display: block;
                    grid-row: 5;
                }
            }

            &__bottom {
                grid-row: 5;

                @include rwd(1024) {
                    grid-row: 7;
                }
            }
        }

        &__logo {
            grid-row: 1;
            padding-bottom: 40px;
        }

        .--text-s.vehicle-title {
            color: #A7A7A8;
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid $gray;

            &.no-title {
                min-height: 40px;
            }
        }

        &__items {
            grid-row: 2;
            grid-column: 1 / -1;
            padding-bottom: 30px;
            display: grid;
            grid-template-columns: repeat(11, 1fr 16px) 1fr;

            a {
                @include font-size(18, 28, 400);
            }

            &.footer {
                &-mobile {
                    display: none;
                }

                &-desktop {
                    .link-column {
                        display: flex;
                        flex-direction: column;
        
                        a, span {
                            min-height: 28px;
                        }
        
                        &.col-1 {
                            grid-column: 1 / span 3;
        
                            &:has(.no-title), &:has(.long-line) {
                                grid-column: 1 / span 4;
                            }
                        }
                        &.col-2 {
                            grid-column: 5 / span 3;
        
                            &:has(.no-title), &:has(.long-line) {
                                grid-column: 5 / span 4;
                            }
                        }
                        &.col-3 {
                            grid-column: 9 / span 3;
        
                            &:has(.no-title), &:has(.long-line) {
                                grid-column: 9 / span 4;
                            }
                        }
                        &.col-4 {
                            grid-column: 13 / span 3;
        
                            &:has(.no-title), &:has(.long-line) {
                                grid-column: 13 / span 4;
                            }
                        }
                        &.col-5 {
                            grid-column: 17 / span 3;
        
                            &:has(.no-title), &:has(.long-line) {
                                grid-column: 17 / span 4;
                            }
                        }
                        &.col-6 {
                            grid-column: 21 / span 3;
        
                            &:has(.no-title), &:has(.long-line) {
                                grid-column: 21 / span 4;
                            }
                        }
                    }
                }
            }

            @include rwd(1150) {
                padding-bottom: 40px;

                &.footer {
                    &-mobile {
                        display: grid;

                        .link-column {
                            grid-column: 1 / -1;
                            display: grid;
                            grid-template-columns: 1fr 1fr 1fr;
                            column-gap: 16px;
                            margin-top: 40px;
    
                            &:nth-child(1) {
                                margin-top: 0;
                            }
    
                            .vehicle-title {
                                grid-column: 1 / -1;
                            }
    
                            a, span {
                                min-height: 28px;
                            }
                        }
                    }

                    &-desktop {
                        display: none;
                    }
                }
            }

            @include rwd(600) {
                &.footer {
                    &-mobile {
                        .link-column {
                            grid-template-columns: 1fr 1fr;
                        }
                    }
                }
            }
        }

        &__text {
            grid-row: 4;
            grid-column: 1 / span 7;
            align-self: start;
            padding-bottom: 50px;
            padding-top: 40px;

            .--title-s {
                margin-bottom: 20px;
            }

            .inline-text {
                display: inline-block;
            }

            @include rwd(1024) {
                grid-column: 1 / -1;
            }

            @include rwd(600) {
                max-width: 89%;
            }
        }

        &__social {
            grid-row: 4;
            grid-column: 17 / -1;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            padding-bottom: 50px;
            padding-top: 40px;

            p {
                margin-bottom: 16px;
            }

            &-buttons {
                display: flex;
                a {
                    margin-left: 16px;

                    &:nth-child(1) {
                        margin-left: 0;
                    }

                    img {
                        width: 24px;
                    }
                }
            }

            @include rwd(1024) {
                grid-column: 1 / -1;
                grid-row: 6;
                align-items: flex-start;
            }

            @include rwd(600) {
                max-width: 89%;
            }
        }

        &__terms {
            grid-row: 7;
            grid-column: 1 / -1;
            padding-top: 32px;
            display: flex;
            justify-content: space-between;

            .copyright {
                color: #D8D8D8;
                display: flex;
                align-items: flex-start;
            }

            .links {
                a {
                    margin-left: 24px;
                }
            }

            @include rwd(1024) {
                grid-row: 8;
                padding-bottom: 40px;
            }

            @include rwd(800) {
                flex-direction: column;
                justify-content: flex-start;

                .links {
                    margin-top: 32px;

                    a:nth-child(1) {
                        margin-left: 0;
                    }

                    a:nth-child(2) {
                        margin-left: 20;
                    }
                }
            }

            @include rwd(600) {
                .copyright {
                    max-width: 89%;
                    flex-direction: column;
                }
            }
        }
    }
}

