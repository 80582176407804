.whatsapp-icon {
    position: fixed;
    z-index: 9999;
    font-size: medium;
    line-height: normal;
    margin: 0;
    padding: 0;
    background-color: white;
    border-radius: 50%;
    width: 3.5em;
    height: 3.5em;
    bottom: 2em;
    right: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 1px 0 #02ff17, 0 3px 15px 0 rgba(25, 150, 0, 0.3);
    transition: all .2s ease-in-out;

    svg {
        width: 55%;

        path {
            fill: #51C85D;
        }
    }
}

.whatsapp-icon:hover {
    box-shadow: 0 0 1px 0 #02ff17, 0 3px 15px 0 rgba(25, 150, 0, 0.8);
}