.ego-modal {
    display: none;
    position: fixed;
    z-index: 10;
    inset: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    border: 0;
    background-color: rgba(0, 0, 0, .8);
    overflow-y: auto;

    &__wrapper {
        display: grid;
        align-items: flex-start;
        width: 100%;
        min-height: 100%;
        padding: 20px;
    }

    &__box {
        position: relative;
        top: 54px;
        display: block;
        margin: 0 auto 80px;
        width: 100%;
        max-width: 700px;
        background-color: #fff;
        border-radius: 16px;
        cursor: default;
        transition: top .2s ease-in-out;
        @include font-size(18, 24);

        $box: &;

        &.--small {
            max-width: 400px;
        }

        .padding-24 {
            padding: 24px;
        }

        &__body {
            position: relative;
            
            &--page {
                padding: 24px;
                display: none;

                &.--active {
                    display: block;
                }

                #{$box}__close-btn {
                    position: absolute;
                    top: 25px;
                    right: 25px;
                    border: 0;
                    background: none;
                    outline: none;
                    padding: 5px;
                    margin: 0;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
        
                    .times {
                        width: 14px;
                        height: 14px;
        
                        * {
                            transition: stroke $timming ease-in-out;
                            stroke: $primary-color;
                        }

                    }

                    &:hover {
                        .times {
                            * {
                                stroke: $primary-color-darker;
                            }
                        }
                    }
                }

                #{$box}__back-btn {
                    position: absolute;
                    top: 25px;
                    left: 25px;
                    border: 0;
                    background: none;
                    outline: none;
                    padding: 5px;
                    margin: 0;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
        
                    .chevron-left {
                        width: 8px;
                        height: 14px;
        
                        * {
                            transition: stroke $timming ease-in-out;
                            stroke: $primary-color;
                        }
                    }

                    &:hover {
                        .chevron-left {
                            * {
                                stroke: $primary-color-darker;
                            }
                        }
                    }
                }
            }
        }

        &__content {
            &-header {
                text-align: center;
                margin-bottom: 16px;

                .icon {
                    margin-bottom: 16px;
                }

                p {
                    @include font-size(14, 20);
                    color: $gray;
                    margin: 1rem 0;
                }
            }
        }
    }

    &__content-block {
        margin-bottom: 30px;
    }

    $modal: &;

    &.--open {
        #{$modal}__box {
            top: 44px;
        }
    }

    @include device-md {
        padding: 0;

        &__wrapper {
            align-items: end;
        }

        &__box {
            margin: 80px auto 0;
            bottom: 28px;
            top: auto;
        }

        &.--open {
            #{$modal}__box {
                top: auto;
            }
        }
    }
}
