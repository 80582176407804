h1 {
    @include font-size(48, 56, 700, -2);

    @include device-md {
        @include font-size(32, 40, 700, -2);
    }
}

h2 {
    @include font-size(32, 40, 700, -2);

    @include device-md {
        @include font-size(26, 34, 700, -2);
    }
}

h3 {
    @include font-size(20, 24, 400);

    strong {
        font-weight: 700;
    }
}

h4 {
    @include font-size(16, 16, 700, 2);
    margin-bottom: 1rem;

    &.--bottomline {
        padding-bottom: 24px;
        margin-bottom: 34px;
        border-bottom: 1px solid $mid-gray;
    }
}

.--book {
    font-weight: 600;
}

.--title-l {
    @include font-size(30, 34, 400, -0);
}

.--title-m {
    @include font-size(24, 28, 700, 0);
}

.--title-s {
    @include font-size(20, 24, 400, 0);
}

.--subtitle {
    @include font-size(16, 16, 700, 2);
    text-transform: uppercase;
}

.--text-l {
    @include font-size(18, 28, 400, 0);
}

.--text-m {
    @include font-size(16, 24, 400, 0);
}

.--text-s {
    @include font-size(14, 20, 400, 0);
}

.--text-xs {
    @include font-size(12, 18, 400, 0);
}

.--link-s {
    @include font-size(14, 20, 700, 0);
}

.--link-xs {
    @include font-size(12, 18, 700, 0);
}