.btn-large {
    padding: 8px 24px;
    @include font-size(16, 22, 700, 0);
    background-repeat: no-repeat;
    background-position: center right 16px;

    @include device-md {
        padding: 8px 16px;
    }

    @include device-sm {
        width: 100%;
    }
}
.btn-small {
    padding: 4px 16px;
    @include font-size(14, 18, 700, 0);

    @include device-sm {
        width: 100%;
    }
}

.btn-large, .btn-small {
    border-radius: 48px;
    border: none;
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    text-align: center;

    &.primary-btn {
        background-color: $primary-color;
        border: 1px solid $primary-color;
        color: $white;
        transition: all $timming  ease-in-out;

        &.disabled {
            background-color: $light-gray;
            border-color: $light-gray;
            color: $gray;
            pointer-events: none;
            cursor: default;
        }

        &:hover {
            background-color: $primary-color-darker;
            border: 1px solid $primary-color-darker;
        }
        &:focus {
            box-shadow: 0px 0px 0px 4px rgba(76, 13, 224, 0.2);
        }
    }

    &.secondary-btn {
        background-color: $dark-gray;
        color: $white;
        transition: all $timming  ease-in-out;

        &.disabled {
            background-color: $light-gray;
            color: $gray;
            pointer-events: none;
        }

        &:hover {
            background-color: $primary-color-darker;
            color: $white;
        }
        &:focus {
            background-color: $primary-color-darker;
            color: $white;
            box-shadow: 0px 0px 0px 4px rgba(76, 13, 224, 0.2);
        }
    }

    &.black-btn {
        background-color: $black;
        border: 1px solid $black;
        color: $white;
        transition: all $timming  ease-in-out;

        &.disabled {
            background-color: $light-gray;
            border-color: $light-gray;
            color: $gray;
            pointer-events: none;
            cursor: default;
        }

        &:hover {
            background-color: $dark-gray;
            border: 1px solid $dark-gray;
        }
        &:focus {
            box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.2);
        }
    }

    &.outline-btn {
        background-color: $white;
        color: $primary-color;
        border: 1px solid $primary-color;
        transition: all $timming  ease-in-out;

        &.with-icon {
            display: flex;
            align-items: center;
            gap: 8px;
        }

        &.disabled {
            background-color: #F7F7F7;
            color: $gray;
            border: 1px solid #A7A7A8;
            pointer-events: none;
        }

        svg.stroke * {
            transition: stroke $timming ease-in-out;
        }
        
        &:hover {
            background-color: $primary-color;
            color: $white;
            border: 1px solid $primary-color;
    
            svg.stroke * {
                stroke: currentColor;
            }
        }
        &:focus {
            background-color: $primary-color;
            color: $white;
            border: 1px solid $primary-color;
            box-shadow: 0px 0px 0px 4px rgba(76, 13, 224, 0.2);
        }
    }

    &.icon-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3px !important;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: $white;
        color: $primary-color;
        border: 1px solid $primary-color;
        transition: all $timming  ease-in-out;

        &.disabled {
            background-color: #F7F7F7;
            color: $gray;
            border: 1px solid #A7A7A8;
            pointer-events: none;
        }

        svg.stroke * {
            transition: stroke $timming ease-in-out;
        }
        &:hover {
            background-color: $primary-color;
            color: $white;
            border: 1px solid $primary-color;
    
            svg.stroke * {
                stroke: currentColor;
            }
        }
        &:focus {
            background-color: $primary-color;
            color: $white;
            border: 1px solid $primary-color;
            box-shadow: 0px 0px 0px 4px rgba(76, 13, 224, 0.2);
        }
    }

    &.block-btn {
        width: 100%;
    }

    &.price-btn {
        @include font-size(18, 22, 700);
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        column-gap: 10px;
        width: 100%;
        padding: 16px 24px;
        border-radius: 16px;
        border: 1px solid $primary-color;
        color: $primary-color;
        background-color: $white;
        text-align: left;
        transition: color $timming ease-in-out, background-color $timming ease-in-out;

        &.not-clickable {
            pointer-events: none;
        }

        &.disabled {
            pointer-events: none;
            border-color: $light-gray;
            background-color: transparent;
            color: $gray;
        }

        .break {
            display: none;
            flex-grow: 1;
            flex-basis: 100%;

            a {
                @include font-size(14, 20, 700, 0);
                color: inherit;
                text-decoration: underline;
            }
        }

        &.--selected {
            background-color: $primary-color;
            border: 1px solid $primary-color;
            color: $white;

            &.--active {
                .break {
                    display: block;
                }
            }

            &.disabled {
                pointer-events: none;
                background-color: $light-gray;
                border-color: $light-gray;
                color: $gray;
            }
        }
    }

    &.fb-btn {
        color: $white;
        border: 1px solid $primary-color;
        background: url('../images/facebook-white.svg') no-repeat 16px center, #3C4F8C;

        &:hover {
            background: url('../images/facebook-white.svg') no-repeat 16px center, #324174;
        }
    }

    &.google-btn {
        color: $gray;
        border: 1px solid #A7A7A8;
        background: url('../images/google.svg') no-repeat 16px center, $white;
        &:hover {
            background: url('../images/google.svg') no-repeat 16px center, $lighter-gray;
        }
    }

    &.--loading,
    &.loading {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        gap: 10px;
        pointer-events: none;
        
        &::after {
            content: '';
            display: inline-block;
            width: 22px;
            height: 22px;
            background: url('../images/spinner.svg') no-repeat center center;
            background-size: contain;
            animation: spin 2s infinite linear;
        }
    }
}

.btn-small {
    &.--loading,
    &.loading {
        &::after {
            width: 18px;
            height: 18px;
        }
    }
}

.text-link {
    cursor: pointer;
    background: none;
    border: 0;
    padding: 0;

    &.with-icon {
        display: inline-flex;
        align-items: center;
        gap: 10px;

        svg * {
            stroke: currentColor;
        }
    }

    &.primary-link{
        @include font-size(14, 20, 700, 0);
        color: $primary-color;
        text-decoration: underline;

        &.inherit {
            font-size: inherit;
            line-height: inherit;
        }

        &.disabled{
            color: #A7A7A8;
            pointer-events: none;
        }
        &:hover{
            color: $primary-color-darker;
        }
    }

    &.secondary-link {
        @include font-size(16, 24, 700, 0);
        color: $dark-gray;
    }

    &.danger-link{
        @include font-size(14, 20, 700, 0);
        color: $danger-color;
        text-decoration: underline;

        &.disabled{
            color: #A7A7A8;
            pointer-events: none;
        }
    }

    &.black-link{
        @include font-size(14, 20, 700, 0);
        color: $black;
        text-decoration: underline;

        &.disabled{
            color: #A7A7A8;
            pointer-events: none;
        }

        &:hover{
            color: $primary-color-darker;
        }
    }

    &.nav-link{
        color: $black;
        transition: color $timming ease-in-out;
        text-decoration: none;
        display: inline-block;

        &::after{
            content: "";
            display: block;
            width: 100%;
            height: 2px;
            margin-top: 4px;
            background: $primary-color;
            transition: transform $timming ease-in-out;
            transform: scale(0);
        }
        &:hover{
            color: $primary-color;
        }
        &:hover::after{
            transform: scale(1);
        }
        &--active{
            display: inline-block;
            color: $primary-color;
            &::after{
                content: "";
                display: block;
                width: 100%;
                height: 2px;
                margin-top: 4px;
                background: $primary-color;
                transition: transform $timming ease-in-out;
                transform: scale(1);
            }
        }
    }
}

.social-media-btn {
    border-radius: 50%;
    background-color: $white;
    padding: 8px;
    width: 40px;
    height: 40px;
    display: block;
}

.social-media-btn:hover {
    background-color: $primary-color-darker;

    img {
        filter: brightness(0) invert(1);
    }
}

@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}