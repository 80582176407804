* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body, html {
    width: 100%;
    scroll-behavior: smooth;
    &.no-scroll{
        overflow: hidden;
    }
    &.block{
        pointer-events: none;
    }
}

body, button {
    font-family: 'ToyotaType', sans-serif;
}

.wrapper {
    display: block;
    width: 100%;
    position: relative;
    max-width: $max_width_wrapper;
    margin-left: auto;
    margin-right: auto;
    padding-left: 80px;
    padding-right: 80px;

    @include device-lg {
        padding-left: 32px;
        padding-right: 32px;
    }

    @include device-md {
        padding-left: 16px;
        padding-right: 16px;
    }
}

section {
    padding-top: 60px;
    padding-bottom: 60px;

    &:first-of-type {
        padding-top: 83px;
    }

    &.page-top-navigation {
        margin-top: 44px;
        padding-bottom: 24px;

        & + section {
            padding-top: 0;
        }

        svg {
            margin-top: 5px;
        }
    }

    @include device-md {
        &:first-of-type {
            padding-top: 67px;
        }
    }
}

strong {
    font-weight: 700;
}

a {
    text-decoration: none;
    color: inherit;
}